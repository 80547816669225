import Product from '../Product';

const CardDisplay = ({ productsData }) => (
    <ul className='product-list product-list--card'>
        {productsData.products.map((product) => (
            <Product
                type="standard"
                product={product}
            />
        )) ?? []}
    </ul>
);

export default CardDisplay;