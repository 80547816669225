import { Container, Row, Col } from "react-bootstrap";
import { SUPPORT_EMAIL } from "../../constants/constants";
import Meta from "../../components/Meta";

const PrivacyPolicy = () => {
  return (<>
    <Meta
        title='プライバシーポリシー | ふるぷら'
        description='ふるぷらのプライバシーポリシーについてのページです。'
        keywords={['ふるぷら', '古着', 'プライバシーポリシー']}
    />
    <Container className="my-5">
        <Row className="justify-content-center">
            <Col xs={12} md={8} lg={8}>
                <h1 className="fs-3 mb-4 text-center fw-bold">プライバシーポリシー</h1>
                <section>
                    <h2 className='fs-6 mb-1 fw-bold'>ふるぷら株式会社 プライバシーポリシー</h2>
                    <p>
                        ふるぷら株式会社（以下、「当社」といいます。）は、インターネットショッピングおよびレンタルサービスをはじめとした各種サービス（以下、「サービス」といいます。）を提供するにあたり、お客様の個人情報の重要性を深く認識しています。
                        当社は、個人情報の適切な取扱いを徹底するため、以下のプライバシーポリシーを定め、これを遵守し、個人情報の適正な管理・保護に努めます。
                    </p>
                    <ol className="list-styled">
                        <li>当社は、個人情報の保護に関する法律（個人情報保護法）および関連法令、ガイドラインを遵守し、適切な管理を行います。</li>
                        <li>当社は、個人情報を取得する際には、その利用目的を明確にし、適法かつ公正な手段で取得します。また、取得した個人情報は、明示した利用目的の範囲内で適切に利用し、目的外の利用を行わないよう管理措置を講じます。</li>
                        <li>当社は、お客様の同意を得た場合、法令に基づく場合、または業務遂行上必要な範囲で委託業者等へ提供する場合を除き、取得した個人情報を第三者に提供することはありません。</li>
                        <li>当社は、個人情報の漏えい、滅失、毀損を防止するため、組織的・技術的・物理的な安全管理措置を講じ、適切な管理を行います。また、法令等で定められた保存期間を経過した情報、もしくは利用目的が達成され不要となった情報は、速やかに廃棄または消去いたします。</li>
                        <li>当社は、個人情報に関する苦情および相談に対して迅速かつ誠実に対応するための窓口を設置し、お客様からのご要望に合理的な範囲内で対応いたします。</li>
                        <li>当社は、社会情勢や技術動向の変化を考慮し、個人情報保護の取り組みを定期的に見直し、継続的に改善を行います。</li>
                    </ol>
                    <p>制定日: 2025年2月1日</p>
                    <p>ふるぷら株式会社 代表取締役社長　市東 豪</p>
                </section>
                <section>
                    <h2 className='fs-6 mb-1 fw-bold'>第1条 取得する個人情報の範囲</h2>
                    <p>当社は、サービスの提供および運営にあたり、適用される法令に基づき、以下の個人情報を取得いたします。</p>
                    <ol className="list-styled">
                        <li>
                            アカウントおよび利用者（以下、「ユーザー」といいます。）情報
                            <ul className="list-styled">
                                <li>サービスへのログインおよび利用時に必要な情報（アカウントID、パスワード）</li>
                                <li>アカウント登録時に提供される情報（氏名、性別、生年月日、郵便番号、メールアドレス）</li>
                                <li>ユーザーが登録する追加情報（住所、電話番号、SNSアカウント情報、身長、体型に関するデータなど）</li>
                            </ul>
                        </li>
                        <li>
                            取引および決済に関する情報
                            <ul className="list-styled">
                                <li>商品の購入やレンタル時に発生する取引履歴および取引内容に関する情報</li>
                                <li>クレジットカード情報、銀行口座情報、電子マネー、コンビニ決済、収納代行、代引きに関する情報</li>
                                <li>ポイント、クーポン、キャッシュバックの利用履歴</li>
                            </ul>
                        </li>
                        <li>
                            ユーザーからの問い合わせに関する情報
                            <ul className="list-styled">
                                <li>お問い合わせフォームやカスタマーサポート経由で提供される情報（氏名、メールアドレス、問い合わせ内容）</li>
                            </ul>
                        </li>
                        <li>
                            本人確認および法令に基づく情報
                            <ul className="list-styled">
                                <li>本人確認のための公的書類（運転免許証、マイナンバーカード、健康保険証、住民票の写し等）およびそれに記載された情報</li>
                                <li>法令に基づき収集が必要な情報（マネーロンダリング対策、犯罪収益移転防止法に基づく情報など）</li>
                            </ul>
                        </li>
                        <li>
                            端末およびアクセス情報
                            <ul className="list-styled">
                                <li>サービス利用時に自動的に取得される情報（IPアドレス、端末識別情報、OS情報、ブラウザの種類）</li>
                                <li>インターネット接続に関する情報（リファラー情報、閲覧したURL、アクセス日時、利用履歴、Cookie情報）</li>
                                <li>位置情報（ユーザーが許可した場合）</li>
                            </ul>
                        </li>
                        <li>
                            広告およびマーケティングに関する情報
                            <ul className="list-styled">
                                <li>広告配信の最適化のためのデータ（広告識別子情報など）</li>
                                <li>ユーザーのサービス利用動向や行動履歴の分析結果</li>
                                <li>サーバーログ情報および利用パターンの記録</li>
                            </ul>
                        </li>
                        <li>
                            従業員および取引先に関する情報
                            <ul className="list-styled">
                                <li>当社の従業員（派遣職員、アルバイトを含む）に関する情報（氏名、住所、経歴、職歴、連絡先）</li>
                                <li>取引先の担当者に関する情報（氏名、所属企業、役職、連絡先）</li>
                            </ul>
                        </li>
                        <li>
                            第三者から取得する情報
                            <ul className="list-styled">
                                <li>ユーザーの同意を得て、提携企業や第三者から提供される情報</li>
                                <li>法令に基づき取得する情報</li>
                                <li>公開情報（SNSやウェブサイト上で公開されている情報など）</li>
                            </ul>
                        </li>
                    </ol>
                    <p>当社が取得する個人情報は、全てユーザーの意思に基づいて提供されるものです。一部の情報を提供いただけない場合は、特定のサービスの利用が制限されることがありますのであらかじめご了承ください。</p>
                </section>
                <section>
                    <h2 className='fs-6 mb-1 fw-bold'>第2条 個人情報の利用目的</h2>
                    <p>当社は、第1条に記載した個人情報を適用法令に従い以下の目的で利用します。</p>
                    <ol className="list-styled">
                        <li>
                            サービスの提供および運営
                            <ul className="list-styled">
                                <li>お客様がサービスにログインし、利用できるようにするため</li>
                                <li>商品の売買・レンタル・発送および取引の履行</li>
                                <li>料金の請求、支払い処理、ポイント管理のため</li>
                                <li>カスタマーサポートおよび問い合わせ対応のため</li>
                                <li>サービスに関する重要な通知（利用規約の変更、障害情報など）をお知らせするため</li>
                                <li>アカウント登録情報の自動入力機能を提供するため</li>
                                <li>退会後のお客様に対する連絡および各種お問い合わせ対応のため</li>
                            </ul>
                        </li>
                        <li>
                            セキュリティおよび不正行為防止
                            <ul className="list-styled">
                                <li>不正アクセス、詐欺行為、規約違反行為の防止・検知</li>
                                <li>サービスの安全性向上のためのセキュリティ対策</li>
                                <li>システムの保守、運用の改善</li>
                            </ul>
                        </li>
                        <li>
                            広告・マーケティング
                            <ul className="list-styled">
                                <li>サービスや関連商品の広告・プロモーションの配信</li>
                                <li>メールマガジンの送信</li>
                                <li>ダイレクトメールの送付</li>
                                <li>キャンペーン、懸賞企画、アンケートの実施</li>
                                <li>当社および第三者広告主による広告の配信（ターゲティング広告を含む）</li>
                            </ul>
                            ※ お客様が広告配信を希望しない場合は、設定変更や配信停止の手続きを行うことができます。
                        </li>
                        <li>
                            サービスの改善および研究開発
                            <ul className="list-styled">
                                <li>ユーザーの利用傾向を分析し、サービスの利便性を向上させるため</li>
                                <li>マーケティングデータの調査・分析</li>
                                <li>新たなサービス・商品の開発</li>
                                <li>大学・研究機関との共同研究（論文への掲載、学術発表を含む）</li>
                            </ul>
                        </li>
                        <li>
                            従業員および取引先の管理
                            <ul className="list-styled">
                                <li>従業員の採用、労務管理、福利厚生の提供</li>
                                <li>退職者の情報管理</li>
                                <li>取引先（パートナー企業）の情報管理および契約履行</li>
                            </ul>
                        </li>
                        <li>
                            法令遵守および契約の履行
                            <ul className="list-styled">
                                <li>法律に基づく記録保存および報告義務の履行</li>
                                <li>裁判所、警察、その他の行政機関からの要請に対応するため</li>
                                <li>マネーロンダリング防止、不正取引対策のため</li>
                                <li>その他、法令上必要な措置を講じるため</li>
                            </ul>
                        </li>
                        <li>
                            上記の目的のほか、法令で定められた場合や追加の利用目的が生じた場合には、事前にお客様の同意を取得した上で個人情報を利用します。
                        </li>
                    </ol>
                    <p>
                        お客様が当社のサービスを利用するためには、当社が上記の目的で個人情報を利用することが必要です。
                        個人情報の提供にご同意いただけない場合、サービスの一部または全部をご利用いただけない場合がありますので、ご了承ください。
                    </p>
                </section>
                <section>
                    <h2 className='fs-6 mb-1 fw-bold'>第3条 個人情報の提供および共有</h2>
                    <p>当社は、個人情報の保護を最優先に考え、法令で認められる場合またはお客様の同意をいただいた場合に限り、第三者に対して個人情報を提供することがあります。以下の状況において、必要な範囲で個人情報を提供・共有することがあります。</p>
                    <ol className="list-styled">
                        <li>
                            サービスの提供および品質向上
                            <ul className="list-styled">
                                <li>商品・サービスの販売、発送、決済処理、カスタマーサポートを行うため</li>
                                <li>サービスの機能向上、システムの最適化を行うため</li>
                                <li>不正行為の検知および防止のためのセキュリティ対策を実施するため</li>
                            </ul>
                        </li>
                        <li>
                            業務委託先への提供<br />
                            当社は、業務の一部を信頼できる外部事業者に委託する場合があります。これにより、以下のような業務において個人情報を提供することがあります。
                            <ul className="list-styled">
                                <li>決済処理（クレジットカード会社、決済代行会社）</li>
                                <li>商品の配送（運送会社、倉庫業者）</li>
                                <li>ユーザーサポート（カスタマーサポート業務委託先）</li>
                                <li>広告配信（マーケティング支援企業）</li>
                                <li>サイト分析および最適化（データ分析会社）</li>
                            </ul>
                            当社は、委託先と適切な契約を締結し、個人情報の適切な取り扱いを義務付けるとともに、必要な監督を行います。
                        </li>
                        <li>
                            新たなサービスの開発および研究
                            <ul className="list-styled">
                                <li>新サービスや機能の検討・開発のためのデータ分析</li>
                                <li>大学・研究機関と連携した学術研究（統計分析、論文発表を含む）</li>
                            </ul>
                            これらの目的で個人情報を提供する場合は、匿名化または統計データとして処理し、特定の個人を識別できない形で取り扱います。
                        </li>
                        <li>
                            法令に基づく提供
                            <ul className="list-styled">
                                <li>裁判所、警察、監督官庁などの公的機関から適法な要請があった場合</li>
                                <li>法的義務を履行するために必要な場合</li>
                                <li>人の生命、身体または財産の保護のために必要であり、お客様の同意を得ることが困難な場合</li>
                            </ul>
                        </li>
                    </ol>
                    <p>
                        当社は、上記の目的に該当しない限り、お客様の個人情報を第三者に提供することはありません。
                        また、お客様がご自身の個人情報の第三者提供を希望しない場合、適用される法令の範囲内で提供の停止を申請することができます。
                    </p>
                </section>
                <section>
                    <h2 className='fs-6 mb-1 fw-bold'>第4条 プライバシーポリシーの変更について</h2>
                    <p>
                        当社は、個人情報の適正な取り扱いを確保し、サービス内容や法令の改正に対応するため、本プライバシーポリシーを随時見直し、変更することがあります。
                        重要な変更があった場合は、適用開始日を明示し通知を行います。
                    </p>
                </section>
                <section>
                    <h2 className='fs-6 mb-1 fw-bold'>第5条 クッキー（Cookie）の利用およびデータの外部送信について</h2>
                    <p>当社のサービスでは、お客様の利便性向上、サービス品質の改善、および最適な広告配信を目的として、クッキー（Cookie）およびその他の識別技術を使用し、お客様の情報を取得・利用・送信することがあります。</p>
                    <ol className="list-styled">
                        <li>
                            クッキー（Cookie）の利用目的
                            <ul className="list-styled">
                                <li>サービスの利便性向上: ログイン状態の維持や、入力情報の自動補完</li>
                                <li>アクセス解析: サイトの利用状況を分析し、UI/UXの改善を行うため</li>
                                <li>広告配信の最適化: お客様の興味・関心に基づいた広告を表示するため</li>
                                <li>不正行為の防止: 不正ログインや不正アクセスを検知するため</li>
                            </ul>
                        </li>
                        <li>
                            データの外部送信<br />
                            当社は、サービス改善や広告配信のため、以下のような外部事業者にデータを提供することがあります。
                            <ul className="list-styled">
                                <li>アクセス解析ツール（Google Analytics など）：ユーザーのサイト利用状況を解析</li>
                                <li>広告配信サービス（Google Ads、Facebook広告など）：ターゲティング広告の配信</li>
                                <li>マーケティングツール（CRMツールなど）：ユーザーの嗜好を分析し、適切な情報を提供</li>
                            </ul>
                        </li>
                        <li>
                            クッキーの管理方法<br />
                            お客様は、以下の方法でクッキーの利用を管理することができます。
                            <ul className="list-styled">
                                <li>ブラウザ設定の変更：クッキーの受け入れ可否を設定し、特定のクッキーをブロックすることが可能です。</li>
                                <li>オプトアウトページの利用：広告配信事業者のオプトアウトページから、ターゲティング広告の配信を無効化できます。</li>
                            </ul>
                            なお、クッキーを無効化した場合、一部のサービスが正常に動作しない場合がございますので、ご了承ください。
                        </li>
                    </ol>
                </section>
                <section>
                    <h2 className='fs-6 mb-1 fw-bold'>第6条 個人情報の開示、訂正等の手続きについて</h2>
                    <p>当社は、お客様の権利を尊重し、保有する個人情報に関して以下の手続きを受け付けます。</p>
                    <ol className="list-styled">
                        <li>
                            開示等の請求について<br />
                            お客様は、当社が保有する個人情報について、以下の請求を行うことができます。
                            <ul className="list-styled">
                                <li>開示請求：当社が保有するお客様の個人情報の開示</li>
                                <li>第三者提供記録の開示：当社が第三者へ提供した個人情報の記録</li>
                                <li>利用目的の通知：個人情報の利用目的の確認</li>
                                <li>訂正請求：個人情報の内容が事実と異なる場合の訂正</li>
                                <li>利用停止請求：不適切な個人情報の利用の停止</li>
                                <li>第三者提供の停止請求：個人情報の第三者提供の停止</li>
                            </ul>
                        </li>
                        <li>
                            開示等の請求方法<br />
                            請求を行う場合は、以下の電子書類を添えて、メールでご提出ください。
                            <ul className="list-styled">
                                <li>個人情報の開示請求書</li>
                                <li>個人情報の訂正請求書（該当する場合）</li>
                                <li>個人情報の利用停止請求書（該当する場合）</li>
                                <li>
                                    本人確認書類（以下のいずれか）
                                    <ul className="list-styled">
                                        <li>運転免許証</li>
                                        <li>パスポート</li>
                                        <li>マイナンバーカード（通知カードは不可）</li>
                                        <li>健康保険証</li>
                                        <li>住民票</li>
                                    </ul>
                                </li>
                                <li>
                                    代理人による請求の場合
                                    <ul className="list-styled">
                                        <li>法定代理人の場合：代理権を証明する書類</li>
                                        <li>任意代理人の場合：当社所定の委任状および本人の印鑑証明書</li>
                                    </ul>
                                </li>
                            </ul>
                            ※ 本籍地などの機微情報が記載されている場合は、塗りつぶしたうえでご提出ください。
                        </li>
                        <li>
                            手数料<br />
                            開示請求および利用目的の通知請求に関しては、事務手数料として 1回の請求につき1,000円 をご負担いただきます。
                            手数料は振込または所定の方法でお支払いいただきます。
                            なお、訂正・利用停止・第三者提供の停止請求については、手数料はかかりません。
                            詳細な請求手続きについては、第7条の窓口までお問い合わせください。
                        </li>
                    </ol>
                </section>
                <section>
                    <h2 className='fs-6 mb-1 fw-bold'>第7条 個人情報の取扱いに関するお問い合わせ・苦情の受付</h2>
                    <p>当社は、個人情報の取扱いに関するご相談、苦情、および異議申し立てを受け付け、迅速かつ誠実に対応いたします。</p>
                    <ol className="list-styled">
                        <li>
                            お問い合わせ窓口<br />
                            個人情報の開示、訂正、利用停止、第三者提供の停止、およびその他のご相談については、以下の窓口までご連絡ください。<br />
                            メールアドレス: <a href={`mailto:${SUPPORT_EMAIL}`}>【{SUPPORT_EMAIL}】</a>
                        </li>
                        <li>
                            同意の撤回について<br />
                            お客様は、当社が取得・利用している個人情報に関して、同意を撤回することができます。
                            同意を撤回した場合、当社は可能な範囲で速やかに対応いたします。ただし、法令上の義務や契約履行に必要な情報は、同意撤回後も一定期間保持することがあります。
                        </li>
                    </ol>
                </section>
            </Col>
        </Row>
    </Container>
    </>);
}

export default PrivacyPolicy;