import { apiSlice } from './apiSlice';
import { ORDERS_URL } from '../constants/constants';

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (order) => ({
                url: ORDERS_URL,
                method: 'POST',
                body: {...order},
            }),
            invalidatesTags: ['Orders'],
        }),
        getOrderDetails: builder.query({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        getOrderShippingAddress: builder.query({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}/shipping`,
            }),
            keepUnusedDataFor: 5,
        }),
        getMyOrders: builder.query({
            query: () => ({
                url: `${ORDERS_URL}/mine`,
            }),
            providesTags: ['Orders'],
            keepUnusedDataFor: 60,
        }),
        returnRentalProduct: builder.mutation({
            query: (id) => ({
                url: `${ORDERS_URL}/${id}/return`,
                method: 'PUT',
            }),
        }),
        purchaseRentalProduct: builder.mutation({
            query: (rentalItemId) => ({
                url: `${ORDERS_URL}/rentals/${rentalItemId}/purchase`,
                method: 'POST'
            }),
            invalidatesTags: ['Orders'],
        }),
        extendRental: builder.mutation({
            query: (data) => ({
                url: `${ORDERS_URL}/rentals/${data.rentalItemId}/extend`,
                method: 'POST',
                body: {
                    extensionPeriod: data.extensionPeriod,
                }
            }),
            invalidatesTags: ['Orders'],
        }),
        cancelOrderItems: builder.mutation({
            query: (data) => ({
                url: `${ORDERS_URL}/${data.orderId}/cancel`,
                method: 'PUT',
                body: {
                    purchaseItemIds: data.purchaseItemIds,
                    rentalItemIds: data.rentalItemIds,
                    cancellationReason: data.cancellationReason,
                },
            }),
        }),
        finalPaymentCheck: builder.mutation({
            query: (data) => ({
                url: `${ORDERS_URL}/${data.orderId}/final-payment-check`,
                method: 'PUT',
                body: {paymentIntentId: data.paymentIntentId, totalPrice: data.totalPrice},
            }),
            invalidatesTags: ['Orders'],
        }),
        paymentFailed: builder.mutation({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}/payment-failed`,
                method: 'PUT',
            }),
        }),
    }),
});

export const { 
    useCreateOrderMutation, 
    useGetOrderDetailsQuery, 
    useGetOrderShippingAddressQuery,
    useGetMyOrdersQuery,
    useReturnRentalProductMutation,
    usePurchaseRentalProductMutation,
    useExtendRentalMutation,
    useCancelOrderItemsMutation,
    useFinalPaymentCheckMutation,
    usePaymentFailedMutation,
}  = ordersApiSlice;