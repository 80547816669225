// components/Sidebar/Filter/SizeFilter.js
import { useState, useEffect } from 'react';
import { SIZE } from '../../../constants/productConstants';

const SizeFilter = ({ query, updateQueryParams }) => {
    const querySizes = query.size ? query.size.split('-') : [];

    const [localSizes, setLocalSizes] = useState(querySizes);

    useEffect(() => {
        setLocalSizes(querySizes);
    }, [query.size]);

    const handleToggleSize = (key) => {
        setLocalSizes((prev) =>
            prev.includes(key)
                ? prev.filter((item) => item !== key)
                : [...prev, key]
        );
    };

    const handleApply = () => {
        updateQueryParams({
            size: localSizes.length ? localSizes.join('-') : null,
        });
    };

    const handleClear = () => {
        setLocalSizes([]);
        updateQueryParams({ size: null });
    };

    return (<>
        <div className='size-filter'>
            {Object.entries(SIZE).map(([key, label]) => {
                const isSelected = localSizes.includes(key);
                return (
                    <button
                        key={key}
                        className={`size-filter__button ${isSelected ? 'size-filter__button--active' : ''}`}
                        onClick={() => handleToggleSize(key)}
                    >
                        {label}
                    </button>
                );
            })}
        </div>
        <div className="filter-sidebar__buttons">
            <button className="filter-sidebar__button filter-sidebar__button--apply" onClick={handleApply}>OK</button>
            <button className="filter-sidebar__button filter-sidebar__button--clear" onClick={handleClear}>リセット</button>
        </div>
    </>);
};

export default SizeFilter;