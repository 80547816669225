import { useState } from "react";
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormContainer from "../../components/FormContainer";
import { saveShippingAddress } from "../../slices/cartSlice";
import CheckoutSteps from "../../components/CheckoutSteps";
import Meta from "../../components/Meta";

const ShippingScreen = () => {
    const cart = useSelector((state) => state.cart);
    const { shippingAddress } = cart;

    const [postalCode, setPostalCode] = useState(shippingAddress?.postalCode || '');
    const [prefecture, setPrefecture] = useState(shippingAddress?.prefecture || '');
    const [city, setCity] = useState(shippingAddress?.city || '');
    const [streetAddress, setStreetAddress] = useState(shippingAddress?.streetAddress || '');
    const [buildingName, setBuildingName] = useState(shippingAddress?.buildingName || '');

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchAddress = async (cleanedZip) => {
        // 郵便番号が7桁の数字であることを確認
        const zipRegex = /^\d{7}$/;
        if (!zipRegex.test(cleanedZip)) {
            setError('有効な郵便番号を入力してください（例：1234567）。');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${cleanedZip}`);
            const data = await response.json();
            if (data.status === 200 && data.results) {
                const result = data.results[0];
                setPrefecture(result.address1);
                setCity(result.address2);
                setStreetAddress(result.address3);
            } else {
                setError('住所が見つかりませんでした。郵便番号を確認してください。');
            }
        } catch (err) {
            setError('住所の取得中にエラーが発生しました。再度お試しください。');
        } finally {
            setLoading(false);
        }
    };

    const handlePostalCodeChange = (e) => {
        const inputPostalCode = e.target.value;
    
        // ハイフンを含んだ状態の郵便番号をそのまま保存
        setPostalCode(inputPostalCode);
    
        // ハイフンを削除した郵便番号を作成
        const cleanedPostalCode = inputPostalCode.replace(/-/g, '');
    
        // ハイフンを除いた数字が7桁になったら住所を自動取得
        if (cleanedPostalCode.length === 7) {
            fetchAddress(cleanedPostalCode);
        }
    };
    

    const submitHandler = (e) => {
        e.preventDefault();
        const errors = [];

        if (!postalCode) {
            errors.push('郵便番号');
        }
        if (!prefecture) {
            errors.push('都道府県');
        }
        if (!city) {
            errors.push('市区町村');
        }
        if (!streetAddress) {
            errors.push('番地');
        }
        if (errors.length > 0) {
            setError(`${errors.join('、')}を入力してください。`);
            return;
        }

        setError('');  // エラーをクリア
        dispatch(saveShippingAddress({ postalCode, prefecture, city, streetAddress, buildingName }));
        navigate('/payment');
    };
    

    return (<>
        <Meta
            title='お届け先 | ふるぷら'
            description='ふるぷらのお届け先入力ページです。'
            robots='noindex, nofollow'
        />
        <FormContainer>
            <CheckoutSteps step1 step2 />

            <h1>お届け先</h1>

            {error && <Alert variant="danger">{error}</Alert>}

            <Form onSubmit={submitHandler}>
                <Form.Group controlId="postalCode" className='my-2'>
                    <Form.Label>郵便番号</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="例: 123-4567 または 1234567"
                        value={postalCode}
                        onChange={handlePostalCodeChange}
                    />
                    {loading && <Spinner animation="border" size="sm" className="mt-2" />}
                </Form.Group>
                <Form.Group controlId="prefecture" className='my-2'>
                    <Form.Label>都道府県</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="都道府県"
                        value={prefecture}
                        onChange={(e) => setPrefecture(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="city" className='my-2'>
                    <Form.Label>市区町村</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="市区町村"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="streetAddress" className='my-2'>
                    <Form.Label>番地</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="番地"
                        value={streetAddress}
                        onChange={(e) => setStreetAddress(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="buildingName" className='my-2'>
                    <Form.Label>建物名</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="建物名（任意）"
                        value={buildingName}
                        onChange={(e) => setBuildingName(e.target.value)}
                    />
                </Form.Group>

                <Button type="submit" variant="primary" className="my-2">
                    Continue
                </Button>
            </Form>
        </FormContainer>
    </>)
}

export default ShippingScreen;
