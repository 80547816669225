import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card, Badge, Container, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from '../../components/StripeCheckoutForm';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import ShopInfoModal from '../../components/ShopInfoModal';
import { 
    useGetOrderDetailsQuery,
} from '../../slices/ordersApiSlice';
import {
    useStripePaymentMutation
} from '../../slices/stripeApiSlice';
import { formatDate ,calculateDaysLeft } from '../../utils/dateUtils';
import { FaStore } from 'react-icons/fa';
import { STRIPE_PUBLISHABLE_KEY } from '../../constants/constants';
import Meta from '../../components/Meta';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const renderTooltip = (description) => (
    <Tooltip id="button-tooltip">
        {description}
    </Tooltip>
);

const OrderScreen = () => {
    const { id: orderId } = useParams();
    const navigate = useNavigate();

    const { data: order, refetch, isLoading, error } = useGetOrderDetailsQuery(orderId);

    const [
        stripePayment,
        {isLoading: loadingStripe, error: errorStripe}
    ] = useStripePaymentMutation();

    const [clientSecret, setClientSecret] = useState("");
    const [paymentIntentId, setPaymentIntentId] = useState("");
    
    // --- ① 金額が0円 & pending の場合のみ、直接ステータス更新して refetch ---
    useEffect(() => {
        // まだ支払いが完了していない & 金額が0円の場合
        if (order && order.status === 'pending' && order.totalPrice === 0) {
            const makeOrderPaid = async () => {
                try {
                    await stripePayment(order).unwrap();
                    // ステータス更新後に refetch して最新データを取得
                    refetch();
                } catch (err) {
                    toast.error(err?.data?.message || err.message);
                }
            }
            makeOrderPaid();
        }
    }, [order, stripePayment, refetch]);

    // --- ② 通常の(0円以外の)支払いフロー ---
    useEffect(() => {
        // 既に clientSecret を取得していれば何もしない
        if (clientSecret) return;

        // 金額が0円、または order がまだ取得できていない、または
        // Order ステータスが pending 以外なら Stripe API に問い合わせは不要
        if (!order || order.totalPrice === 0 || order.status !== 'pending') return;
        
        // Stripe 決済リクエスト中や、エラーがある最中なら呼ばない
        if (loadingStripe || errorStripe) return;

        const fetchClientSecret = async () => {
            try {
                const response = await stripePayment(order).unwrap();
                setClientSecret(response.clientSecret);
                setPaymentIntentId(response.paymentIntentId);
            } catch (err) {
                toast.error(err?.data?.message || err.message);
            }
        };

        fetchClientSecret();
    }, [order, stripePayment, loadingStripe, errorStripe]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
        locale: 'ja', // Stripe UIを日本語に設定
    };

    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({ shopId: null, startDate: null, endDate: null, daysLeft: null });

    const handleShowModal = (shopId, startDate, endDate, daysLeft) => {
        setModalInfo({ shopId, startDate, endDate, daysLeft });
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handlePaymentSuccess = () => {
        navigate('/order-complete');
    }

    return (
        isLoading 
            ? <Loader /> 
            : error ? (
                <Message variant="danger">{ error?.data?.message || error.error }</Message>
            )
            : (<>
                <Meta
                    title={`ご注文受付ID: ${order.id} | ふるぷら`}
                    description={`ご注文受付ID: ${order.id} の詳細情報です。`}
                    robots='noindex, nofollow'
                />
                <Container>
                    <h1>ご注文受付ID: {order.id}</h1>
                    <Row>
                        <Col md={8}>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h2>お支払い方法</h2>
                                    <p>
                                        <strong>お支払い方法: </strong>
                                        {order.paymentMethod}
                                    </p>
                                    { order.status === 'paid' ? (
                                        <Message variant='success'>
                                            {formatDate(order.paidAt)} にお支払い済み
                                        </Message>
                                    ) : order.status === 'pending' ? (
                                        <Message variant='danger'>
                                            お支払いが済んでおりません。
                                            ご指定の手順でお支払いをお願いいたします。
                                        </Message>
                                    ) : (
                                        <Message variant='danger'>
                                            一定時間お支払いが確認できなかったため、このオーダーはキャンセルされました。
                                        </Message>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h2>商品</h2>
                                    { order.RentalItems.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image src={item.image} alt={item.name} fluid rounded />
                                                </Col>
                                                <Col>
                                                    <Link to={`/product/${item.productId}`}>
                                                        {item.name}
                                                    </Link>
                                                </Col>
                                                <Col md={4}>
                                                    レンタル： ¥{ item.finalPrice }
                                                    <br />
                                                    <small className='small-text'>
                                                        {item.rentalPeriod === '4days' ? '3泊4日' : item.rentalPeriod === '2weeks' ? '2週間' : item.rentalPeriod === '1month' ? '1ヶ月' : '無期限'}
                                                    </small>
                                                </Col>
                                                <Col md={2}>
                                                    {item.isCanceled ? (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={renderTooltip(item.description || "キャンセル理由が記載されていません。")}
                                                        >
                                                            <Badge bg='danger' style={{ cursor: 'pointer' }}>キャンセル</Badge>
                                                        </OverlayTrigger>
                                                    ) : item.inStore ? (
                                                        <div 
                                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#007bff', textDecoration: 'underline', fontWeight: 'bold' }} 
                                                            onClick={() => {
                                                                const { status, rentalPeriod } = item;
                                                                const isRentedStatus = ['rented', 'extended', 'overdue'].includes(status);
                                                                const daysLeft = isRentedStatus && rentalPeriod !== 'unlimited'
                                                                    ? calculateDaysLeft(item.endDate)
                                                                    : null;
                                                                handleShowModal(item.shopId, item.startDate, item.endDate, daysLeft);
                                                            }}
                                                        >
                                                            <FaStore style={{ marginRight: '5px' }} />
                                                            お店<small className='small-text'>で受け取る</small>
                                                        </div>
                                                    ) : (
                                                        <div>配送</div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}
                                    { order.PurchaseItems.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image src={item.image} alt={item.name} fluid rounded />
                                                </Col>
                                                <Col>
                                                    <Link to={`/product/${item.productId}`}>
                                                        {item.name}
                                                    </Link>
                                                </Col>
                                                <Col md={4}>
                                                    購入： ¥{ item.finalPrice }
                                                </Col>
                                                <Col md={2}>
                                                    {item.isCanceled ? (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={renderTooltip(item.description || "キャンセル理由が記載されていません。")}
                                                        >
                                                            <Badge bg='danger' style={{ cursor: 'pointer' }}>キャンセル</Badge>
                                                        </OverlayTrigger>
                                                    ) : item.inStore ? (
                                                        <div 
                                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'blue' }} 
                                                            onClick={() => handleShowModal(item.shopId)}
                                                        >
                                                            <FaStore style={{ marginRight: '5px' }} />
                                                            お店<small className='small-text'>で受け取る</small>
                                                        </div>
                                                    ) : (
                                                        <div>配送</div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>   
                                    ))}
                                    { order.RentalExtensions.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image src={item.RentalItem.image} alt={item.RentalItem.name} fluid rounded />
                                                </Col>
                                                <Col>
                                                    <Link to={`/product/${item.RentalItem.productId}`}>
                                                        {item.RentalItem.name}
                                                    </Link>
                                                </Col>
                                                <Col md={4}>
                                                    レンタル延長： ¥{ item.fee }
                                                    <br />
                                                    <small className='small-text'>
                                                        {item.rentalPeriod === '3days' ? '2泊3日' : '1週間'}
                                                    </small>
                                                </Col>
                                                <Col md={2}>
                                                    <div 
                                                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'blue' }} 
                                                        onClick={() => {
                                                            const { status, rentalPeriod } = item.RentalItem;
                                                            const isRentedStatus = ['rented', 'extended', 'overdue'].includes(status);
                                                            const daysLeft = isRentedStatus && rentalPeriod !== 'unlimited' 
                                                                ? calculateDaysLeft(item.endDate) 
                                                                : null;
                                                        
                                                            handleShowModal(item.shopId, item.startDate, item.endDate, daysLeft);
                                                        }}                                                        
                                                    >
                                                        <FaStore style={{ marginRight: '5px' }} />
                                                        詳細<small className='small-text'>を見る</small>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h2>お届け先</h2>
                                    <p>
                                        <strong>お名前: </strong> {order.userName}
                                    </p>
                                    {/* <p>
                                        <strong>Email: </strong> {order.user.email}
                                    </p> */}
                                    <p>
                                        <strong>住所: </strong>
                                        {'〒'}{order.postalCode} {order.prefecture} {order.city} {order.streetAddress} {order.buildingName}   
                                    </p>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <ListGroup variant='flush'>
                                    <ListGroup.Item>
                                        <h2>ご注文内容（税込み）</h2>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>商品:</Col>
                                            <Col>
                                                {order.itemsPrice}円
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>送料:</Col>
                                            <Col>
                                                {order.shippingPrice}円
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>合計:</Col>
                                            <Col>
                                                {order.totalPrice}円
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    { order.status === 'pending' && (
                                            <ListGroup.Item>
                                                {loadingStripe && <Loader />}
                                                {!clientSecret ? (
                                                    <Message variant="danger">
                                                        支払い情報を取得中にエラーが発生しました。
                                                    </Message>
                                                ) : (
                                                    <Elements options={options} stripe={stripePromise}>
                                                        <StripeCheckoutForm onSuccess={handlePaymentSuccess} orderId={order.id} paymentIntentId={paymentIntentId} totalPrice={order.totalPrice} />
                                                    </Elements>
                                                )}
                                            </ListGroup.Item>
                                        )}
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                    <ShopInfoModal
                        show={showModal}
                        shopId={modalInfo.shopId}
                        startDate={modalInfo.startDate}
                        endDate={modalInfo.endDate}
                        daysLeft={modalInfo.daysLeft}
                        onHide={handleCloseModal}
                    />
                </Container>
            </>)
    )
}

export default OrderScreen;