import { Link } from 'react-router-dom';
import CategoryPopup from '../CategoryPopup';
import { FaChevronRight } from 'react-icons/fa';
import { useGetCategoryTreeQuery } from '../../slices/categoriesApiSlice';
import { LinkContainer } from 'react-router-bootstrap';
import FilterSidebar from './FilterSidebar';

const NavSidebar = () => {
    const { data: categoryTree } = useGetCategoryTreeQuery();

    return (
        <>
            <div className='sidebar__header'>
                <h2>探す</h2>
            </div>
            <ul className='sidebar__list'>
                <LinkContainer to='/search'>
                    <li>
                        新着
                    </li>
                </LinkContainer>
                {/* <li>
                <Link to='/area'>地域から探す</Link>
                </li> */}
                <LinkContainer to='/shops'>
                    <li>
                        店舗から探す
                    </li>
                </LinkContainer>
                {/* <li>
                <Link to='/brand'>ブランドから探す</Link>
                </li>
                <li>
                <Link to='/detail'>こだわり条件から探す</Link>
                </li> */}
            </ul>
            <section className='category-search'>
                <div className='sidebar__header'>
                    <h3>カテゴリー</h3>
                </div>
                <ul>
                    {categoryTree &&
                        categoryTree.map((parentCategory) => (
                        <CategoryPopup 
                            key={parentCategory.id} 
                            parentCategory={parentCategory} 
                        />
                    ))}
                </ul>
            </section>
        </>
    );
};

export default NavSidebar;