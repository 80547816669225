const genderFilters = [
    // { label: 'すべて', value: 'all' },
    { label: 'メンズ', value: 'men', icon: 'man_2' },
    { label: 'レディース', value: 'women', icon: 'woman_2' },
    // { label: 'キッズ', value: 'kids', icon: 'boy' },
];
const GenderFilter = ({ query, updateQueryParams }) => {
    const selectedGender = query.gender || 'all';
  
    const handleClick = (value) => {
        if (selectedGender === value) {
            updateQueryParams({ gender: null });
        } else {
            updateQueryParams({ gender: value });
      }
    };
  
    return (
        <div role="tablist" className="gender-filter gender-filter--sidebar">
            {genderFilters.map((gender) => (
                <a
                    key={gender.value}
                    onClick={() => handleClick(gender.value)}
                    className={`${gender.value} ${selectedGender === gender.value ? 'active' : ''}`}
                    role="tab"
                    aria-selected={selectedGender === gender.value}
                    aria-label={gender.label}
                    style={{ cursor: 'pointer' }}
                >
                    {gender.icon && (
                        <span className="material-symbols-outlined">{gender.icon}</span>
                    )}
                    <div>{gender.label}</div>
                </a>
            ))}
        </div>
    );
};
  
export default GenderFilter;