import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FRONTEND_BASE_URL } from "../constants/constants";

const JsonLdContext = createContext();

export const JsonLdProvider = ({ children }) => {
  const [commonFragments] = useState([
    {
      "@type": "Organization",
      "name": "ふるぷら",
      "url": FRONTEND_BASE_URL,
    }
  ]);

  const [pageFragments, setPageFragments] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setPageFragments([]);
  }, [location]);

  const registerFragment = useCallback((fragment) => {
    setPageFragments((prev) => [...prev, fragment]);
  }, []);

  const fragments = [...commonFragments, ...pageFragments];

  return (
    <JsonLdContext.Provider value={{ fragments, registerFragment }}>
      {children}
    </JsonLdContext.Provider>
  );
};

export const useJsonLd = () => useContext(JsonLdContext);