import { useMemo, useState } from 'react';
import { Card, Button, Container, Table, Badge } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useGetMyOrdersQuery } from '../slices/ordersApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import ShopInfoModal from '../components/ShopInfoModal';
import Tabs from '../components/Tabs';
import { calculateDaysLeft, formatDate } from '../utils/dateUtils';
import GalleryDisplay from '../components/GalleryDisplay';
import Meta from '../components/Meta';

const OrderHistoryScreen = () => {
    const { data: orders, isLoading, error, refetch } = useGetMyOrdersQuery();
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({ shopId: null, startDate: null, endDate: null, daysLeft: null, orderId: null, rentalItemId: null, productPrice: null });
    const [activeTab, setActiveTab] = useState('general'); 

    const unpaidOrders = useMemo(() => orders ? orders.filter(order => order.status === 'pending' ) : [], [orders]);
    const rentalItems = useMemo(() => {
        if (!orders || !orders.length) return [];
        
        const items = [];
        orders.forEach(order => {
            if (order.RentalItems && order.RentalItems.length) { // RentalItems が存在するかチェック
                order.RentalItems.forEach(item => {
                    if ((item.status === 'rented' || item.status === 'extended') && item.isDelivered && (item.rentalPeriod === 'unlimited' || calculateDaysLeft(item.endDate) > 3)) {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
        });
        return items;
    }, [orders]);
    const overdueItems = useMemo(() => {
        if (!orders || !orders.length) return [];
        
        const items = [];
        orders.forEach(order => {
            if (order.RentalItems && order.RentalItems.length) { // RentalItems が存在するかチェック
                order.RentalItems.forEach(item => {
                    if (item.status === 'overdue' || ((item.status === 'rented' || item.status === 'extended') && calculateDaysLeft(item.endDate) < 0 && item.rentalPeriod !== 'unlimited')) {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
        });
        return items;
    }, [orders]);
    const nearDueDateItems = useMemo(() => {
        if (!orders || !orders.length) return [];

        const items = [];
        orders.forEach(order => {
            if (order.RentalItems && order.RentalItems.length) { // RentalItems が存在するかチェック
                order.RentalItems.forEach(item => {
                    if ((item.status === 'rented' || item.status === 'extended') && 0 <= calculateDaysLeft(item.endDate) && calculateDaysLeft(item.endDate) <= 3 && item.rentalPeriod !== 'unlimited') {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
        });
        return items;
    }, [orders]);
    const undeliveredItems = useMemo(() => {
        if (!orders || !orders.length) return [];
        
        const items = [];
        orders.forEach(order => {
            if (order.RentalItems && order.RentalItems.length) { // RentalItems が存在するかチェック
                order.RentalItems.forEach(item => {
                    if (!item.isDelivered && !item.isCanceled) {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
            if (order.PurchaseItems && order.PurchaseItems.length) { // PurchaseItems が存在するかチェック
                order.PurchaseItems.forEach(item => {
                    if (!item.isDelivered && !item.isCanceled) {
                        items.push({ orderId: order.id, ...item });
                    }
                });
            }
        });

        // 店舗ごとに商品をまとめる
        const groupedItems = items.reduce((acc, item) => {
            const key = item.inStore ? item.shopId : 'delivery';
            if (!acc[key]) acc[key] = [];
            acc[key].push(item);
            return acc;
        }, {});

        return Object.entries(groupedItems).map(([key, items]) => ({
            shopId: key !== 'delivery' ? key : null,
            items,
        }));
    }, [orders]);

    const handleShowModal = (shopId, startDate, endDate, daysLeft, orderId, rentalItemId, productPrice) => {
        setModalInfo({ shopId, startDate, endDate, daysLeft, orderId, rentalItemId, productPrice });
        setShowModal(true);
    };
    

    const handleCloseModal = () => {
        setShowModal(false);
        setModalInfo({ shopId: null, startDate: null, endDate: null, daysLeft: null, orderId: null, rentalItemId: null });
    };

    const generalOrders = useMemo(() => {
        if (!orders) return [];
        return orders.filter(order => 
            order.status !== 'canceled' &&
            ((order.PurchaseItems && order.PurchaseItems.length > 0) ||
            (order.RentalItems && order.RentalItems.length > 0))
        );
    }, [orders]);

    const extendedOrders = useMemo(() => {
        if (!orders) return [];
        return orders.filter(order =>
            order.status !== 'canceled' &&
            (!order.PurchaseItems || order.PurchaseItems.length === 0) &&
            (!order.RentalItems || order.RentalItems.length === 0)
        );
    }, [orders]);

    const canceledOrders = useMemo(() => {
        if (!orders) return [];
        return orders.filter(order => order.status === 'canceled');
    }, [orders]);

    const tabs = [
        { label: '一般', value: 'general', ariaSelected: activeTab === 'general' },
        { label: '延長', value: 'extended', ariaSelected: activeTab === 'extended' },
        { label: 'キャンセル', value: 'canceled', ariaSelected: activeTab === 'canceled' },
    ];

    return (<>
        <Meta
            title='注文履歴'
            description='過去の注文履歴を確認できます。'
            robots='noindex, nofollow'
        />
        <Container>
            <div className="order-history-screen">
                <h1 className="my-4">注文履歴</h1>
                {isLoading ? <Loader /> :
                    error ? (
                        <Message variant="danger">{error?.data?.message || error.error}</Message>
                    ) : (<>
                        {unpaidOrders.length > 0 && (<>
                            <h3 className='alert alert-danger mt-4'>お支払いが済んでないオーダー</h3>
                            <div className='d-flex flex-wrap gap-3 justify-content-center justify-content-md-start'>
                                {unpaidOrders.map((order) => (
                                    <LinkContainer to={`/order/${order.id}`} style={{ cursor: 'pointer'}}>
                                        <Card className="mb-3">
                                            <Card.Header>
                                                <small>{order.createdAt ? formatDate(order.createdAt) : '未払い'}</small> <br />
                                                <small>合計: ¥{order.totalPrice}</small>
                                            </Card.Header>
                                            <Card.Body className='order-card'>
                                                <GalleryDisplay
                                                    type='order'
                                                    products={[...order.PurchaseItems, ...order.RentalItems]}
                                                />
                                            </Card.Body>
                                            <Card.Footer>
                                                <LinkContainer to={`/order/${order.id}`}>
                                                    <Button variant="primary">支払う</Button>
                                                </LinkContainer>
                                            </Card.Footer>
                                        </Card>
                                    </LinkContainer>
                                ))}
                            </div>
                        </>)}

                        {overdueItems.length > 0 && (<>
                            <h3 className='alert alert-danger mt-4'>期限切れの商品</h3>
                            <div className='d-flex flex-wrap gap-3 justify-content-center justify-content-md-start'>
                                {overdueItems.map((item) => (
                                    <Card className="mb-3">
                                        <Card.Header>
                                            <small>返却期限: {formatDate(item.endDate)}</small>
                                        </Card.Header>
                                        <Card.Body className='order-card'>
                                            <GalleryDisplay
                                                type='order'
                                                products={[item]}
                                            />
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button
                                                variant="danger"
                                                onClick={() => handleShowModal(item.shopId, item.startDate, item.endDate, calculateDaysLeft(item.endDate), item.orderId, item.id, item.price)}
                                            >
                                                確認する
                                            </Button>
                                        </Card.Footer>
                                    </Card>
                                ))}
                            </div>
                        </>)}

                        {undeliveredItems.length > 0 && (<>
                            <h3 className='alert alert-info mt-4'>まだ受け取っていない商品</h3>
                            <div className='d-flex flex-wrap gap-3 justify-content-center justify-content-md-start'>
                                {undeliveredItems.map((group) => (
                                    <>
                                    <Card className="mb-3">
                                        <Card.Header>
                                            {group.shopId ? 'お店で受け取り' : '配送'}
                                        </Card.Header>
                                        <Card.Body className='order-card'>
                                            <GalleryDisplay
                                                type='order'
                                                products={group.items}
                                            />
                                        </Card.Body>
                                        <Card.Footer>
                                            {group.shopId ? (
                                                <Button variant="info" onClick={() => handleShowModal(group.shopId)}>
                                                    お店の情報
                                                </Button>
                                            ) : (
                                                <LinkContainer to={`/order/${group.items[0].orderId}`}>
                                                    <Button variant="primary">詳細を見る</Button>
                                                </LinkContainer>
                                            )}
                                        </Card.Footer>
                                    </Card>
                                    </>
                                ))}
                            </div>
                        </>)}

                        {nearDueDateItems.length > 0 && (<>
                            <h3 className='alert alert-warning mt-4'>返却期限が近い商品</h3>
                            <div className='d-flex flex-wrap gap-3 justify-content-center justify-content-md-start'>
                                {nearDueDateItems.map((item) => (
                                    <>
                                        <Card className="mb-3">
                                            <Card.Header>
                                                <small>返却期限: あと{calculateDaysLeft(item.endDate)}日</small>
                                            </Card.Header>
                                            <Card.Body className='order-card'>
                                                <GalleryDisplay
                                                    type='order'
                                                    products={[item]}
                                                />
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button
                                                    variant="warning"
                                                    onClick={() => handleShowModal(item.shopId, item.startDate, item.endDate, calculateDaysLeft(item.endDate), item.orderId, item.id, item.price)}
                                                >
                                                    確認する
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    </>
                                ))}
                            </div>
                        </>)}

                        {rentalItems.length > 0 && (<>
                            <h3 className='alert alert-warning mt-4'>レンタル中の商品</h3>
                            <div className='d-flex flex-wrap gap-3 justify-content-center justify-content-md-start'>
                                {rentalItems.map((item) => (
                                    <Card className="mb-3">
                                            {item.rentalPeriod !== 'unlimited' && 
                                                <Card.Header>
                                                    <small>返却期限: あと{calculateDaysLeft(item.endDate)}日</small>
                                                </Card.Header>
                                            }
                                        <Card.Body className='order-card'>
                                            <GalleryDisplay
                                                type='order'
                                                products={[item]}
                                            />
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button
                                                variant="warning"
                                                onClick={() => handleShowModal(item.shopId, item.startDate, item.endDate, calculateDaysLeft(item.endDate), item.orderId, item.id, item.price)}
                                            >
                                                確認する
                                            </Button>
                                        </Card.Footer>
                                    </Card>
                                ))}
                            </div>
                        </>)}

                        {orders.length > 0 && (<>
                            <h3 className='alert alert-success mt-4'>全てのオーダー</h3>
                            <Tabs tabs={tabs} onClick={setActiveTab} />

                            <div className='mt-3'>
                                {activeTab === 'general' && (<>
                                    {generalOrders.length === 0 ? (
                                        <Message variant="info">一般のオーダーはありません。</Message>
                                    ) : (
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>id</th>
                                                    <th>商品</th>
                                                    <th>合計</th>
                                                    <th>注文日時</th>
                                                    <th>お支払日</th>
                                                    <th>アクション</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {generalOrders.map((order) => (
                                                    <tr key={order.id}>
                                                        <td>{order.id}</td>
                                                        <td>
                                                            <ul>
                                                                {order.PurchaseItems && order.PurchaseItems.map((item) => (
                                                                    <li key={item.productId}>{item.name}: ¥{item.finalPrice}（購入）</li>
                                                                ))}
                                                                {order.RentalItems && order.RentalItems.map((item) => (
                                                                    <li key={item.productId}>{item.name}: ¥{item.finalPrice}（レンタル）</li>
                                                                ))}
                                                            </ul>
                                                        </td>
                                                        <td>¥{order.totalPrice}</td>
                                                        <td>{order.createdAt ? formatDate(order.createdAt) : '未設定'}</td>
                                                        <td>{order.paidAt ? formatDate(order.paidAt, false) : <Badge bg='danger'>未払い</Badge>}</td>
                                                        <td>
                                                            <LinkContainer to={`/order/${order.id}`}>
                                                                <Button variant="primary" size="sm">詳細</Button>
                                                            </LinkContainer>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </>)}

                                {activeTab === 'extended' && (<>
                                    {extendedOrders.length === 0 ? (
                                        <Message variant="info">延長オーダーはありません。</Message>
                                    ) : (
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>id</th>
                                                    <th>合計</th>
                                                    <th>作成日時</th>
                                                    <th>お支払い日</th>
                                                    <th>アクション</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {extendedOrders.map((order) => (
                                                    <tr key={order.id}>
                                                        <td>{order.id}</td>
                                                        <td>¥{order.totalPrice}</td>
                                                        <td>{order.createdAt ? formatDate(order.createdAt) : '未設定'}</td>
                                                        <td>{order.paidAt ? formatDate(order.paidAt, false) : <Badge bg='danger'>未払い</Badge>}</td>
                                                        <td>
                                                            <LinkContainer to={`/order/${order.id}`}>
                                                                <Button size="sm">詳細</Button>
                                                            </LinkContainer>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </>)}

                                {activeTab === 'canceled' && (<>
                                    {canceledOrders.length === 0 ? (
                                        <Message variant="info">キャンセルされたオーダーはありません。</Message>
                                    ) : (
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>id</th>
                                                    <th>合計</th>
                                                    <th>商品</th>
                                                    <th>キャンセル日時</th>
                                                    <th>アクション</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {canceledOrders.map((order) => (
                                                    <tr key={order.id}>
                                                        <td>{order.id}</td>
                                                        <td>¥{order.totalPrice}</td>
                                                        <td>
                                                            <ul>
                                                                {order.PurchaseItems && order.PurchaseItems.map((item) => (
                                                                    <li key={item.productId}>{item.name} - ¥{item.finalPrice}（購入））</li>
                                                                ))}
                                                                {order.RentalItems && order.RentalItems.map((item) => (
                                                                    <li key={item.productId}>{item.name} - ¥{item.finalPrice}（レンタル）</li>
                                                                ))}
                                                                {
                                                                    (!order.PurchaseItems || order.PurchaseItems.length === 0) &&
                                                                    (!order.RentalItems || order.RentalItems.length === 0) && 
                                                                    <li>延滞料金の請求</li>
                                                                }
                                                            </ul>
                                                        </td>
                                                        <td>{formatDate(order.canceledAt)}</td>
                                                        <td>
                                                            <LinkContainer to={`/order/${order.id}`}>
                                                                <Button size="sm">詳細</Button>
                                                            </LinkContainer>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </>)}
                            </div>
                        </>)}
                    </>)
                }

                <ShopInfoModal
                    show={showModal}
                    shopId={modalInfo.shopId}
                    startDate={modalInfo.startDate}
                    endDate={modalInfo.endDate}
                    daysLeft={modalInfo.daysLeft}
                    orderId={modalInfo.orderId}
                    rentalItemId={modalInfo.rentalItemId}
                    productPrice={modalInfo.productPrice}
                    onHide={handleCloseModal}
                />
            </div>
        </Container>
    </>);
};

export default OrderHistoryScreen;
