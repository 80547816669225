import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Row, Col, ListGroup, Card, Button, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import ImageGallery from '../components/ImageGallery';
import FavoriteButton from '../components/FavoriteButton';
import Meta from '../components/Meta';
import { 
    useGetProductDetailsQuery,
} from '../slices/productsApiSlice';
// import { useCreateReviewMutation } from '../slices/shopsApiSlice';
import { addToCart } from '../slices/cartSlice';
import { COLOR, SIZE } from '../constants/productConstants';
import Breadcrumb from '../components/SEO/Breadcrumb';
import { useJsonLd } from '../context/JsonLdContext';
import { FRONTEND_BASE_URL, RENTAL_RATE_NON_ORG, RENTAL_RATE_ORG, SHIPPING_FEE } from '../constants/constants';

const ProductScreen = () => {

    const { id: productId } = useParams();
    const { registerFragment } = useJsonLd();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [serviceOption, setServiceOption] = useState('buy');
    const [useSubscription, setUseSubscription] = useState(false);

    const [rentalPeriod, setRentalPeriod] = useState('4days');

    const [inStore, setInStore] = useState(true);

    // const [rating, setRating] = useState(0);
    // const [comment, setComment] = useState('');

    const { data: product, isLoading, refetch, error } = useGetProductDetailsQuery(productId);

    // const [createReview, { isLoading: loadingShopReview  }] = useCreateReviewMutation();

    const { userInfo } = useSelector((state) => state.auth);

    const canUseSubscription = userInfo && userInfo.subscription &&
                               userInfo.subscription.usedItems < userInfo.subscription.maxItems &&
                               product?.price <= userInfo.subscription.itemPriceLimit;

    let subscriptionUnavailableReason = '';
    if (!userInfo || !userInfo.subscription) {
        subscriptionUnavailableReason = (
            <>
                <small className="small-text">
                    サブスクリプションプランに加入して、さらにお得にレンタルを楽しむことができます。<br />
                    <Link to="/subscriptions" className='text-warning'>こちら</Link>から加入をご検討ください。
                </small>
            </>
        );
    } else if (userInfo && userInfo.subscription) {
        if (userInfo.subscription.usedItems >= userInfo.subscription.maxItems) {
            subscriptionUnavailableReason = (
                <>
                    <small className="small-text">
                        商品を返却したら、サブスクでまた新しい服をレンタルできます！<br />
                    </small>
                </>
            );
        } else if (product?.price > userInfo.subscription.itemPriceLimit) {
            subscriptionUnavailableReason = (
                <>
                    <small className="small-text">
                        選択した商品の価格が現在のサブスクリプションプランの予算を超えています。<br />
                        <Link to="/subscriptions/upgrade">プランをアップグレード</Link>して、さらに多くの商品をお得にレンタルしましょう。
                    </small>
                </>
            );
        }
    }

    const calculatePrice = (price, serviceOption, useSubscription, userInfo, rentalPeriod) => {
        if (serviceOption === 'rent') {
            if (useSubscription && userInfo && userInfo.subscription) {
                return 0;
            } else if (!useSubscription && userInfo && userInfo.organization) {
                const rate = RENTAL_RATE_ORG[rentalPeriod] || RENTAL_RATE_ORG.default;
                return price * rate;
            } else if (!useSubscription) {
                const rate = RENTAL_RATE_NON_ORG[rentalPeriod] || RENTAL_RATE_NON_ORG.default;
                return price * rate;
            }
        }
        return price;
    };
    

    const addToCartHandler = () => {
        const finalPrice = calculatePrice(product.price, serviceOption, useSubscription, userInfo, rentalPeriod);

        const itemToAdd = {
            ...product,
            serviceOption,
            inStore,
            useSubscription: canUseSubscription && useSubscription,
            rentalPeriod: !useSubscription ? rentalPeriod : 'unlimited',
            finalPrice,
        };
        dispatch(addToCart(itemToAdd));
        navigate('/cart');
    }

    // const submitHandler = async (e) => {
    //     e.preventDefault();
    //     try {
    //         await createReview({
    //             shopId: product.shopId,
    //             rating,
    //             comment,
    //         }).unwrap()
    //         refetch();
    //         toast.success('Review Submitted');
    //         setRating(0);
    //         setComment('');
    //     } catch (err) {
    //         toast.error(err?.data?.message || err.error);
    //     }
    // }

    useEffect(() => {
        if (product && product.status !== 'available') {
            setServiceOption('buy');
        }
    }, [product]);

    const items = useMemo(() => [
        { name: 'ふるぷら', url: '/' },
        { name: product?.shopName || 'ショップ', url: `/shop/${product?.Shop.slug}` },
        { name: product?.parentCategory?.name || 'カテゴリー', url: `/category/${product?.parentCategory?.slug}` },
        { name: product?.category?.name || 'カテゴリー', url: `/category/${product?.parentCategory?.slug}/${product?.category?.slug}` },
        { name: product?.name || 'アイテム詳細', url: '' },
    ], [product]);

    function buildPeopleAudience(genderId) {
        const audienceBlock = {
          "@type": "PeopleAudience",
        };
    
        switch (genderId) {
            case 1:
                audienceBlock.suggestedGender = "Unisex";
                break;
            case 2:
                audienceBlock.suggestedGender = "https://schema.org/Male";
                break;
            case 3:
                audienceBlock.suggestedGender = "https://schema.org/Female";
                break;
            case 4:
                audienceBlock.suggestedGender = "Unisex";
                audienceBlock.suggestedMinAge = "3";
                audienceBlock.suggestedMaxAge = "12";
                break;
            default:
                audienceBlock.suggestedGender = "Unisex";
                break;
        }
        return audienceBlock;
    }

    useEffect(() => {
        if (product) {
            const colorName = product.color ? COLOR[product.color] : null;
            const sizeName = product.size ? SIZE[product.size] : null;
            const audienceData = buildPeopleAudience(product.gender);
            const productFragment = {
                "@type": "Product",
                "name": product.name,
                "image": [
                    product.image,
                    ...(product.subImages || [])
                ],
                "description": product.description,
                "brand": {
                    "@type": "Brand",
                    "name": product.shopName,
                },
                ...(colorName && { "color": colorName }),
                ...(sizeName && { "size": sizeName }),
                "audience": audienceData,
                "offers": {
                    "@type": "Offer",
                    "url": window.location.href,
                    "price": product.price,
                    "priceCurrency": "JPY",
                    "itemCondition": "https://schema.org/UsedCondition",
                    "availability": product.status === 'available' ? "InStock" : "OutOfStock",
                    "shippingDetails": {
                        "@type": "OfferShippingDetails",
                        "shippingRate": {
                            "@type": "MonetaryAmount",
                            "value": SHIPPING_FEE,
                            "currency": "JPY"
                        },
                        "shippingLabel": "Delivery"
                    },
                    "hasMerchantReturnPolicy": true,
                    "returnPolicy": {
                        "@type": "MerchantReturnPolicy",
                        "merchantReturnDays": 8,
                        "returnPolicyCategory": "https://schema.org/NonReturnable",
                        "description": "誤配送や明らかに実際の商品と記載内容に差異がある場合のみ、8日以内の返品ができます。",
                        "url": `${FRONTEND_BASE_URL}/help/cancellation-return-policy`
                    }
                },
            };
            registerFragment(productFragment);
        }
    }, [product, registerFragment]);

    return (
        <Container>
            {
                isLoading ? (<h2>Loading...</h2>)
                : error ? (<Message variant='danger'> { error?.data?.message || error.error } </Message>) 
                : product ? (<>
                    <Meta
                        title={product.name}
                        description={product.description}
                        keywords={['古着', '通販', product.name, product.shopName, product.category?.name, product.shopArea?.name]}
                        image={product.image}
                        robots='index, follow'
                    />
                    <Breadcrumb
                        items={items}
                    />
                    <Row className='mt-5'>
                        <Col md={5}>
                            <ImageGallery images={[product.image, ...(product.subImages || [])]} />
                        </Col>
                        <Col md={4}>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h3>{product.name}</h3>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <FavoriteButton itemId={productId} isProduct={true} />
                                    {/* <Rating shop={`【${product.shop.name}】`} value={product.rating} text={`${product.numReviews} reviews`} /> */}
                                </ListGroup.Item>
                                <ListGroup.Item>販売価格: {product.price}円(税込み)</ListGroup.Item>
                                <ListGroup.Item>
                                    <Link to={`/shop/${product.Shop.slug}`}><b>{product.shopName}</b></Link>
                                </ListGroup.Item>
                                <ListGroup.Item>商品説明: {product.description}</ListGroup.Item>
                                <ListGroup.Item>カテゴリー: {product.category.name}</ListGroup.Item>
                                <ListGroup.Item>サイズ: {SIZE[product.size]}</ListGroup.Item>
                                <ListGroup.Item>
                                    {product.measurements && product.measurements.length > 0 && (
                                        <div>
                                            <h2>測定項目</h2>
                                            <ul>
                                                {product.measurements.map(measure => (
                                                    <li key={measure.key}>
                                                        {measure.label}: {product.sizeMeasurements[measure.key] || 'N/A'}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <ListGroup variant='flush'>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>小計:</Col>
                                            <Col>
                                                <strong>{calculatePrice(product.price, serviceOption, useSubscription, userInfo, rentalPeriod)}円</strong>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>在庫状況:</Col>
                                            <Col>
                                                <strong>
                                                    {
                                                        product.status === 'available' ? '◯' 
                                                        : product.status === 'rented' ? 'Rented'
                                                        : '品切れ中'
                                                    }
                                                </strong>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    {product.status === 'available' && (
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>オーダー</Col>
                                                <Col>
                                                    <Form.Control
                                                        as="select"
                                                        value={serviceOption}
                                                        onChange={(e) => setServiceOption(e.target.value)}
                                                    >
                                                        <option value="buy">購入</option>
                                                        <option value="rent">レンタル</option>
                                                    </Form.Control>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    )}

                                    {product.status === 'available' && serviceOption === 'rent' && (
                                        <ListGroup.Item>
                                            <Form.Check
                                                type="checkbox"
                                                label="サブスクを使用する"
                                                checked={useSubscription}
                                                onChange={(e) => setUseSubscription(e.target.checked)}
                                                disabled={!canUseSubscription}
                                            />
                                            {subscriptionUnavailableReason} <br />
                                                {userInfo && userInfo.subscription ? (
                                                    <small>
                                                        <strong>サブスクリプションプラン:</strong> {userInfo.subscription.name}
                                                        <br />
                                                        <strong>現在レンタルしている数:</strong> {userInfo.subscription.usedItems}/{userInfo.subscription.maxItems}
                                                        <br />
                                                        <strong>商品価格上限:</strong> ¥{userInfo.subscription.itemPriceLimit}
                                                    </small>
                                                ) : null}
                                        </ListGroup.Item>
                                    )}

                                    {serviceOption === 'rent' && !useSubscription && (
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <Form.Label>レンタル期間：</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        as="select"
                                                        value={rentalPeriod}
                                                        onChange={(e) => setRentalPeriod(e.target.value)}
                                                    >
                                                        {userInfo && userInfo.organization ? (
                                                            <>
                                                                <option value="4days">3泊4日 - 35%</option>
                                                                <option value="2weeks">2週間 - 45%</option>
                                                                <option value="1month">1ヶ月 - 55%</option>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <option value="4days">3泊4日 - 40%</option>
                                                                <option value="2weeks">2週間 - 50%</option>
                                                                <option value="1month">1ヶ月 - 60%</option>
                                                            </>
                                                        )}
                                                    </Form.Control>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    )}

                                    {product.status === 'available' && (
                                        <ListGroup.Item>
                                            <Form.Check
                                                type="checkbox"
                                                label={`店舗で受け取る（${product.shopArea?.name}）`} // エリア名を直接使用
                                                checked={inStore}
                                                onChange={(e) => setInStore(e.target.checked)}
                                            >
                                            </Form.Check>
                                            <small className="small-text">
                                                受け取りで、送料（880円）がかかりません。
                                            </small>
                                        </ListGroup.Item>
                                    )}

                                    <ListGroup.Item>
                                        <Button 
                                            className='btn-block' 
                                            type='button' 
                                            disabled={product.status !== 'available'}
                                            onClick={addToCartHandler}
                                        >
                                            カートに追加
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                            {serviceOption === 'rent' && !useSubscription && userInfo?.organization && (
                                <Row className='mt-3 mx-1'>
                                    <Message variant="success">メンバー限定割引が適用されています！</Message>
                                </Row>
                            )}
                        </Col>
                    </Row>

                    {/* <Row className='review'>
                        <Col md={6}>
                            <h2>【Shop Reviews】 {product.shopName} {product.shopArea}</h2>
                            {product.shop.reviews.length === 0 && <Message>レビューを書いてみましょう</Message>}
                            <ListGroup variant='flush'>
                                {product.shop.reviews.map((review) => (
                                    <ListGroup.Item key={review.id}>
                                        <strong>{review.name}</strong>
                                        <Rating value={review.rating} />
                                        <p>{review.createdAt.substring(0, 10)}</p>
                                        <p>{review.comment}</p>
                                    </ListGroup.Item>
                                ))}
                                <ListGroup.Item>
                                    <h2>レビューを書く</h2>

                                    {loadingShopReview && <Loader />}

                                    {userInfo ? (
                                        <Form onSubmit={ submitHandler }>
                                            <Form.Group controlId='rating' className='my-2'>
                                                <Form.Label>評価</Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    value={rating}
                                                    onChange={(e) => setRating(Number(e.target.value))}
                                                >
                                                    <option value=''>Select...</option>
                                                    <option value='1'>1</option>
                                                    <option value='2'>2</option>
                                                    <option value='3'>3</option>
                                                    <option value='4'>4</option>
                                                    <option value='5'>5</option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId='comment' className='my-2'>
                                                <Form.Label>コメント</Form.Label>
                                                <Form.Control
                                                    as='textarea'
                                                    row='3'
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                ></Form.Control>
                                            </Form.Group>
                                            <Button
                                                disabled={loadingShopReview}
                                                type='submit'
                                                variant='primary'
                                            >
                                                Submit
                                            </Button>
                                        </Form>
                                    ) : (
                                        <Message>
                                            レビューを書くためには <Link to='/login'>sign in</Link> が必要です{' '}
                                        </Message>
                                    )}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row> */}
                </>) : <Message variant='info'>商品が見つかりません</Message>
            }
        </Container>
    )
}

export default ProductScreen;