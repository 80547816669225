import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GENDER_TO_ID } from '../../constants/productConstants';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import FilterSidebar from '../../components/Sidebar/FilterSidebar';
import Tabs from '../../components/Tabs';
import SearchResults from '../../components/SearchResults';
import { useGetProductsQuery } from '../../slices/productsApiSlice';
import { useGetAreasQuery } from '../../slices/areasApiSlice';
import NavSidebar from '../../components/Sidebar/NavSidebar';
import useQueryParams from '../../hooks/useQueryParams';
import Breadcrumb from '../../components/SEO/Breadcrumb';

const SearchScreen = () => {
    const [query, updateQueryParams] = useQueryParams();
    const { parentCategory, category } = useParams();

    const keyword = query.keyword || '';
    const pageNumber = parseInt(query.page, 10) || 1;
    const selectedGender = query.gender || 'all';
    const selectedArea = query.area || 'all';
    const minPrice = query.minPrice ? parseInt(query.minPrice, 10) : '';
    const maxPrice = query.maxPrice ? parseInt(query.maxPrice, 10) : '';
    const sizes = query.size ? query.size.split('-').map(Number) : [];
    const colors = query.color ? query.color.split('-').map(Number) : [];

    const { data: areasData, isLoading: areasLoading, error: areasError } = useGetAreasQuery();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pageNumber, query]);

    const queryParams = useMemo(() => ({
        keyword,
        pageNumber,
        minPrice,
        maxPrice,
        sizes,
        colors,
        ...(parentCategory && { categorySlug: category || parentCategory }),
        ...(selectedGender !== 'all' && { gender: GENDER_TO_ID[selectedGender] }),
        ...(selectedArea !== 'all' && { area: selectedArea }),
    }), [keyword, pageNumber, minPrice, maxPrice, sizes, colors, parentCategory, category, selectedGender, selectedArea]);

    const { data: productsData, isLoading: productsLoading, error: productsError } = useGetProductsQuery(queryParams);

    const areaTabs = useMemo(() => {
        if (!areasData) return [];
        return [
            { label: 'すべて', value: 'all', ariaSelected: selectedArea === 'all' },
            ...areasData
                .filter(area => area.id !== 1)
                .map(area => ({
                    label: area.name,
                    value: String(area.id),
                    ariaSelected: selectedArea === String(area.id),
                })),
        ];
    }, [selectedArea, areasData]);

    if (productsLoading) return <Loader />;
    if (productsError) return <Message variant='danger'>商品データの取得に失敗しました</Message>;
    if (areasError) return <Message variant='danger'>エリアデータの取得に失敗しました</Message>;
    return (
        <>
        <div className='home-wrapper'>
            <div className='home-left'>
                <div className='ms-2'>
                    <Breadcrumb
                        items={[
                            { name: 'ふるぷら', url: '/' },
                            { name: '商品検索', url: '/search' },
                            parentCategory ? { name: parentCategory, url: `/category/${parentCategory}` } : null,
                            (parentCategory && category) ? { name: category, url: `/category/${parentCategory}/${category}` } : null,
                        ].filter(Boolean)}
                    />
                </div>
                <div className="filter-sidebar">
                    <nav className='sidebar'>
                        <FilterSidebar query={query} updateQueryParams={updateQueryParams} />
                        <NavSidebar />
                    </nav>
                </div>
                </div>
                <div className='home-right'>
                <div className='search-heading'>
                    <div className='search-heading__info'>
                        <h1>
                            {keyword ||
                            (
                                category &&
                                productsData?.data &&
                                productsData?.data[0]?.category &&
                                productsData.data[0].category.name
                            ) ||
                            category ||
                            parentCategory ||
                            (selectedArea !== 'all' && `「${areasData?.find(a => a.id === Number(selectedArea))?.name}」の商品`) ||
                            `すべての商品`}
                        </h1>
                        <div className='search-heading__count'>
                            <span>{productsData.meta.count}</span>件
                        </div>
                    </div>
                    <Tabs
                        tabs={areaTabs}
                        onClick={(value) => updateQueryParams({ area: value })}
                    />
                </div>
                <SearchResults
                    productsData={productsData}
                    keyword={keyword}
                />
            </div>
        </div>
        </>
    );
};

export default SearchScreen;