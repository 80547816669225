import { createContext, useState, useEffect } from 'react';

export const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
    const checkIsMobile = () => {
        return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < 768;
    };

    const [isMobile, setIsMobile] = useState(checkIsMobile);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkIsMobile());
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <DeviceContext.Provider value={isMobile}>
            {children}
        </DeviceContext.Provider>
    );
};