import { memo } from "react";
import { Suspense, lazy } from "react";
import useDevice from "../hooks/useDevice";
const MobileHeader = lazy(() => import('./SP/Header'));
const DesktopHeader = lazy(() => import('./PC/Header'));

const Header = memo(() => {
  const isMobile = useDevice();
  
  // モバイルまたはデスクトップのコンポーネントを条件分岐で返す
  const SelectedHeader = isMobile ? MobileHeader : DesktopHeader;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SelectedHeader />
    </Suspense>
  );
})

export default Header;