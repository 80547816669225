import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { SUPPORT_EMAIL } from '../../constants/constants';
import Meta from '../../components/Meta';

const CancellationReturnPolicy = () => {
    const navigate = useNavigate();
    return (<>
        <Meta 
            title='キャンセル・返品・返金ポリシー | ふるぷら'
            description='ふるぷらのキャンセル・返品・返金ポリシーについてのページです。'
            keywords={['ふるぷら', '古着', 'キャンセル', '返品', '返金', 'ポリシー']}
        />
        <Container className='my-5'>
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={5}>
                    <section>
                        <h1 className='fs-6 mb-1 fw-bold'>キャンセル・返品・返金ポリシー</h1>
                        <p>
                            詳細なポリシー内容については、利用規約をご確認ください。
                        </p>
                        <Button
                            variant='primary'
                            onClick={() => navigate('/help/terms-of-service')}
                        >
                            利用規約
                        </Button>
                    </section>

                    <hr style={{ margin: '2rem 0' }} />

                    <section>
                        <h2 className='fs-4 mb-3 fw-bold'>キャンセルポリシー</h2>
                        <div>
                            <h3 className='fs-6 mb-1 fw-bold'>1. キャンセルの可否</h3>
                            <p>注文確定後のキャンセルはできません。</p>
                        </div>
                        <div>
                            <h3 className='fs-6 mb-1 fw-bold'>2. ユーザー規約の同意</h3>
                            <p>
                                ユーザーは、キャンセルポリシーを確認し、同意の上でサービスを利用するものとします。
                            </p>
                        </div>
                    </section>

                    <hr style={{ margin: '2rem 0' }} />

                    <section>
                        <h2 className='fs-4 mb-1 fw-bold'>返品・返金ポリシー</h2>
                        <p>
                            誤配送や、記載内容と実際の商品に明らかな差異がある場合のみ返品が可能です。該当事由が確認された場合は、
                            <a
                                href={`mailto:${SUPPORT_EMAIL}`}
                                style={{ marginLeft: '0.5rem', color: '#0070f3' }}
                            >
                                {SUPPORT_EMAIL}
                            </a>
                            までご連絡ください。
                        </p>
                        <div>
                            <h3 className='fs-6 mb-1 fw-bold'>1. 返品受付期間</h3>
                            <p>商品がお手元に到着してから8日以内</p>
                        </div>
                        <div>
                            <h3 className='fs-6 mb-1 fw-bold'>2. 返送料の負担</h3>
                            <p>返品時の送料はお客様負担となります。注文元の店舗へ直接お送りいただくか、店舗で直接お返しください。</p>
                        </div>
                    </section>
                </Col>
            </Row>
        </Container>
    </>);
};

export default CancellationReturnPolicy;