import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from '../constants/constants';

const baseQuery = fetchBaseQuery({
    baseUrl: API_BASE_URL,
    credentials: 'include',
});

export const apiSlice = createApi({
    baseQuery,
    tagTypes: ['Areas', 'Balance', 'Withdrawals', 'Products', 'Orders', 'Users', 'Favorites','Brand', 'Categories', 'ShopOwner', 'Shop', 'Organization', 'Notifications' ],
    endpoints: (builder) => ({}),
});