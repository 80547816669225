import { Helmet } from "react-helmet-async";
import { useJsonLd } from "../../context/JsonLdContext";

const CombinedJsonLd = () => {
  const { fragments } = useJsonLd();

  const combinedJsonLd = {
    "@context": "https://schema.org",
    "@graph": fragments
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(combinedJsonLd)}
      </script>
    </Helmet>
  );
};

export default CombinedJsonLd;