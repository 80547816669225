import { useState, useMemo, useEffect } from 'react';
import PriceFilter from './Filter/PriceFilter';
import SizeFilter from './Filter/SizeFilter';
import ColorFilter from './Filter/ColorFilter';
import GenderFilter from './Filter/GenderFilter';

const FilterSidebar = ({ query, updateQueryParams }) => {
    const [isBrandOpen, setBrandOpen] = useState(false);
    const [isPriceOpen, setPriceOpen] = useState(false);
    const [isSizeOpen, setSizeOpen] = useState(false);
    const [isColorOpen, setColorOpen] = useState(false);

    const isPriceActive = useMemo(
        () => query.minPrice || query.maxPrice, 
        [query.minPrice, query.maxPrice]
    );
    const isSizeActive = useMemo(
        () => query.size, 
        [query.size]
    );
    const isColorActive = useMemo(
        () => query.color, 
        [query.color]
    );

    useEffect(() => {
        if (isPriceActive) setPriceOpen(true);
        if (isSizeActive) setSizeOpen(true);
        if (isColorActive) setColorOpen(true);
    }, [isPriceActive, isSizeActive, isColorActive]);

    const toggleSection = (section) => {
        switch (section) {
            case 'brand':
                setBrandOpen(!isBrandOpen);
                break;
            case 'price':
                if (isPriceOpen) {
                if (isPriceActive) return;
                    setPriceOpen(false);
                } else {
                    setPriceOpen(true);
                }
                break;
            case 'size':
                if (isSizeOpen) {
                if (isSizeActive) return;
                    setSizeOpen(false);
                } else {
                    setSizeOpen(true);
                }
                break;
            case 'color':
                if (isColorOpen) {
                if (isColorActive) return;
                    setColorOpen(false);
                } else {
                    setColorOpen(true);
                }
                break;
            default:
                break;
        }
    };

    return (
        <div className="mb-4">
            <div className="sidebar__header">
                <h2>こだわり条件</h2>
            </div>

            <GenderFilter query={query} updateQueryParams={updateQueryParams} />

            <div className="filter-sidebar__section">
                <div className="filter-sidebar__header" onClick={() => toggleSection('price')}>
                    <span className="filter-sidebar__header-title">価格</span>
                    <span className="filter-sidebar__header-icon">{isPriceOpen ? '-' : '+'}</span>
                </div>
                {isPriceOpen && (
                    <div className="filter-sidebar__content">
                        <PriceFilter query={query} updateQueryParams={updateQueryParams} />
                    </div>
                )}
            </div>

            <div className="filter-sidebar__section">
                <div className="filter-sidebar__header" onClick={() => toggleSection('size')}>
                    <span className="filter-sidebar__header-title">サイズ</span>
                    <span className="filter-sidebar__header-icon">{isSizeOpen ? '-' : '+'}</span>
                </div>
                {isSizeOpen && (
                    <div className="filter-sidebar__content">
                        <SizeFilter query={query} updateQueryParams={updateQueryParams} />
                    </div>
                )}
            </div>

            <div className="filter-sidebar__section">
                <div className="filter-sidebar__header" onClick={() => toggleSection('color')}>
                    <span className="filter-sidebar__header-title">色</span>
                    <span className="filter-sidebar__header-icon">{isColorOpen ? '-' : '+'}</span>
                </div>
                {isColorOpen && (
                    <div className="filter-sidebar__content">
                        <ColorFilter query={query} updateQueryParams={updateQueryParams} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FilterSidebar;