// export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : '';
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PRODUCTS_URL = '/products';
export const ADMINS_URL = '/admins';
export const USERS_URL = '/users';
export const SHOP_OWNERS_URL = '/shop-owners';
export const SHOPS_URL = '/shops';
export const AREAS_URL = '/areas';
export const ORGANIZATIONS_URL = '/organizations';
export const ORDERS_URL = '/orders';
export const TAGS_URL = '/tags';
export const CATEGORIES_URL = '/categories';
export const BRANDS_URL = '/brands';
export const FAVORITES_URL = '/favorites';
export const NOTIFICATIONS_URL = '/notifications/user';
export const PAYPAL_URL = '/config/paypal';
export const UPLOAD_URL = '/upload';
export const STRIPE_URL = '/stripe';
export const BALANCE_URL = '/balance';
export const SUBSCRIPTIONS_URL = '/subscriptions';
export const SITEMAP_URL = '/sitemap';
export const STRIPE_PUBLISHABLE_KEY = 'pk_live_51OaA80HQ4620qDCA7KalQQvTXQWZLeqL0qP939fut7X25nPu85sVBz51Da6MjnKuSVo3JU2Z0jAOq5m1fr6CN79Z00FhnYKwLK';
export const STRIPE_PUBLISHABLE_KEY_TEST = 'pk_test_51OaA80HQ4620qDCA5uLgRH5kjNSFFh7StObsB36wZ4qyta8IafMd7OF5a1H6tSx2q3hVHBm69QAu8g6KFUKMKIGV00jneuZ889';

export const MAX_TAGS = 2;

export const PAGINATION_MAX_VISIBLE_PAGES = 5;
export const PAGINATION_PAGE_BUFFER = 2;

export const RENTAL_RATE_ORG = {
    '4days': 0.35,
    '2weeks': 0.45,
    '1month': 0.55,
    default: 0.35,
};

export const RENTAL_RATE_NON_ORG = {
    '4days': 0.4,
    '2weeks': 0.5,
    '1month': 0.6,
    default: 0.4,
};

export const SHIPPING_FEE = 880;

export const SUPPORT_EMAIL = 'support@furupura.jp';

export const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL

export const GA_TRACKING_ID = 'G-BL9HSXGKXJ';