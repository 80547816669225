import useDevice from "../hooks/useDevice";
import HomeScreenPC from "./PC/HomeScreen";
import HomeScreenSP from "./SP/HomeScreen";
import { useJsonLd } from "../context/JsonLdContext";
import { useEffect } from "react";
import { FRONTEND_BASE_URL } from "../constants/constants";

const HomeScreen = () => {
    const isMobile = useDevice();
    const { registerFragment } = useJsonLd();

    useEffect(() => {
        registerFragment({
            "@type": "WebSite",
            "url": FRONTEND_BASE_URL,
            "potentialAction": {
                "@type": "SearchAction",
                "target": `${FRONTEND_BASE_URL}/search?q={search_term_string}`,
                "query-input": "required name=search_term_string"
            }
        });
    }, [registerFragment]);
    
    return isMobile ? <HomeScreenSP /> : <HomeScreenPC />;
}

export default HomeScreen;