import Meta from "../components/Meta";
import useDevice from "../hooks/useDevice";
import SearchScreenPC from "./PC/SearchScreen";
import SearchScreenSP from "./SP/SearchScreen";

const SearchScreen = () => {
    const isMobile = useDevice();
    
    return (<>
        <Meta
            title='検索 | ふるぷら'
            description='商品の検索ページです。'
            keywords={['検索', 'ふるぷら', '古着', '下北', '古着屋', '高円寺']}
            robots='index, follow'
        />
        {isMobile ? <SearchScreenSP /> : <SearchScreenPC />}
    </>);
}

export default SearchScreen;