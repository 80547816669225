import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DeviceProvider } from './context/DeviceContext';
import { JsonLdProvider } from './context/JsonLdContext';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Footer from './components/Footer';
import CombinedJsonLd from './components/SEO/CombinedJsonLd';
import ReactGA from 'react-ga4';
import Meta from './components/Meta';
import { FRONTEND_BASE_URL, GA_TRACKING_ID } from './constants/constants';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analyticsの初期化
    ReactGA.initialize(GA_TRACKING_ID); // ここに自分のトラッキングIDを入力

    // ページビューのトラッキング
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  const currentUrl = `${FRONTEND_BASE_URL}${location.pathname}`;
  const computedCanonical = currentUrl.replace(/\/$/, '');

  return (
    <DeviceProvider>
      <JsonLdProvider>
        <Meta
          canonical={computedCanonical}
        />
        <CombinedJsonLd />
        <Header />
        <main className='py-3'>
          <Outlet />
        </main>
        <Footer />
        <ToastContainer />
      </JsonLdProvider>
    </DeviceProvider>
  )
};

export default App;
