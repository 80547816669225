import { Helmet } from 'react-helmet-async';

const Meta = ({
  title,
  description,
  keywords,
  image,
  author,
  type,
  locale,
  siteName,
  canonical,
  robots,
}) => {
  const keywordsContent = Array.isArray(keywords) ? keywords.join(', ') : keywords;

  return (
    <Helmet htmlAttributes={{ lang: 'ja' }}>
      <title>{title}</title>

      {canonical && <link rel="canonical" href={canonical} />}

      <meta name="description" content={description} />
      <meta name="keywords" content={keywordsContent} />
      <meta name="author" content={author} />
      <meta name="robots" content={robots} />

      {/* Open Graph タグ */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {canonical && <meta property="og:url" content={canonical} />}
      {image && <meta property="og:image" content={image} />}
      {type && <meta property="og:type" content={type} />}
      {locale && <meta property="og:locale" content={locale} />}
      {siteName && <meta property="og:site_name" content={siteName} />}

      {/* Twitter Card タグ */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "ふるぷら - '世界にひとつ'を、探しに行こう",
  description:
    "下北、高円寺、吉祥寺。日本が誇るサブカル「古着」を存分に詰め込んだプラットフォーム",
  keywords: ["古着", "下北", "高円寺", "レンタル", "ブランド", "おしゃれ"],
  author: "ふるぷら",
  image: "https://example.com/og-image.jpg", // ソーシャルシェア用画像のURL
  type: "website",
  locale: "ja_JP",
  siteName: "ふるぷら",
  robots: "index,follow",
};

export default Meta;