import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import Loader from '../components/Loader';
import { useRegisterMutation } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';
import Meta from "../components/Meta";

const RegisterScreen = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [registered, setRegistered] = useState(false); // New state variable

    const navigate = useNavigate();

    const [register, { isLoading }] = useRegisterMutation();

    const { userInfo } = useSelector((state) => state.auth);

    const { search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || '/';

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo, redirect, navigate]);

    const validateInputs = () => {
        const errors = {};
        if (!name) errors.name = 'お名前を入力してください';
        if (!email) errors.email = 'メールアドレスを入力してください';
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            errors.email = '有効なメールアドレスを入力してください';
        }
        if (!password) errors.password = 'パスワードを入力してください';
        else if (password.length < 6) errors.password = 'パスワードは6文字以上で入力してください';
        if (password !== confirmPassword) errors.confirmPassword = 'パスワードが一致しません';
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        if (validateInputs()) {
            try {
                await register({ name, email, password }).unwrap();
                toast.success('登録が完了しました。確認メールを送信しましたので、メール内のリンクをクリックしてアカウントを確認してください。');
                setRegistered(true);
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        } else {
            toast.error('入力内容にエラーがあります。修正してください。');
        }
    };

    return (<>
        <Meta
            title='新規登録 | ふるぷら'
            description='ふるぷらの新規登録ページです。'
            keywords={['新規登録', 'ふるぷら', '古着', '下北', '古着屋', '高円寺']}
        />
        <FormContainer>
            <h1>Register</h1>

            {registered ? (
                <Alert variant="success">
                    登録が完了しました。確認メールを送信しましたので、メール内のリンクをクリックして登録を完了してください。
                </Alert>
            ) : (
                <Form onSubmit={submitHandler} noValidate>
                    <Form.Group controlId="name" className="my-3">
                        <Form.Label>お名前</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            disabled={isLoading || registered}
                            isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="email" className="my-3">
                        <Form.Label>メールアドレス</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isLoading || registered}
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="password" className="my-3">
                        <Form.Label>パスワード</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={isLoading || registered}
                            isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="confirmPassword" className="my-3">
                        <Form.Label>パスワード（確認用）</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            disabled={isLoading || registered}
                            isInvalid={!!errors.confirmPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button type="submit" variant="primary" className="mt-2" disabled={isLoading || registered}>
                        {isLoading ? '登録中...' : '新規登録'}
                    </Button>

                    {isLoading && <Loader />}
                </Form>
            )}

            {!registered && (
                <Row className="py-3">
                    <Col>
                        既にアカウントをお持ちの方は{' '} 
                        <Link to={ redirect ? `/login?redirect=${redirect}` : '/login' }>
                            こちら
                        </Link>
                        {' '}からログインしてください。
                    </Col>
                </Row>
            )}

            {registered && (
                <Row className="py-3">
                    <Col>
                        <Link to="/login">ログインはこちら</Link>
                    </Col>
                </Row>
            )}
        </FormContainer>
    </>)
}

export default RegisterScreen;