import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup, Button, Card, Container } from 'react-bootstrap';
import Message from '../components/Message';
import { removeFromCart } from '../slices/cartSlice';
import Meta from '../components/Meta';
import CartItems from '../components/CartItems';

const CartScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;

    const removeFromCartHandler = async (id) => {
        dispatch(removeFromCart(id));
    };

    const { userInfo } = useSelector((state) => state.auth);

    // サブスクリプション利用状況の確認関数
    const checkSubscriptionLimit = () => {
        if (!userInfo || !userInfo.subscription) {
        return true; // サブスクリプションがない場合は制限なし
        }
    
        // カート内のサブスク利用対象商品の集計
        const cartSubscriptionItems = cartItems.filter(item => item.useSubscription);
    
        // サブスクリプションの利用可能量を超えているかの確認
        const isOverLimit = userInfo.subscription.usedItems + cartSubscriptionItems.length > userInfo.subscription.maxItems;
    
        return !isOverLimit;
    };

    const checkoutHandler = () => {
        if (checkSubscriptionLimit()) {
          navigate('/login?redirect=/shipping');
        } else {
          // ここでユーザーに警告を表示する（例: モーダル、トースト通知など）
          alert('サブスクリプションの利用可能回数を超えています。');
        }
    };

    return (
        <Container>
            <Meta
                title='カート | ふるぷら'
                description='ふるぷらのカートページです。'
                robots='noindex, nofollow'
            />
            <Row className='mt-4'>
                <Col md={8}>
                    <h1 style={{ marginBottom: '20px' }}>カート</h1>
                    { cartItems.length === 0 
                        ? (
                            <Message>
                                カートは空です <Link to='/'>Go Back</Link>
                            </Message>
                        )
                        : (
                            <CartItems cartItems={cartItems} removeFromCart={removeFromCartHandler} />
                        )
                    }
                </Col>
                <Col md={4}>
                    <Card>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <h2>
                                    小計 { cartItems.length } 点
                                </h2>
                                { cartItems
                                    .reduce((acc, item) => acc + item.finalPrice, 0)
                                    .toFixed(0)
                                }円 (税込み)
                            </ListGroup.Item>
                            
                            <ListGroup.Item>
                                <Button 
                                    type='button' 
                                    className='btn-block' 
                                    disabled={cartItems.length === 0 || !checkSubscriptionLimit()}
                                    onClick={ checkoutHandler }
                                >
                                    Continue
                                </Button>
                                {!checkSubscriptionLimit() && 
                                    <Message variant='danger mt-3'>
                                        サブスクリプションの無料枠は、あと {userInfo.subscription.maxItems - userInfo.subscription.usedItems} 点です。
                                        {userInfo && userInfo.subscription ? (
                                            <small>
                                                <strong>プラン:</strong> {userInfo.subscription.planName}
                                                <br />
                                                <strong>現在レンタルしている数::</strong> {userInfo.subscription.usedItems}/{userInfo.subscription.maxItems}
                                                <br />
                                                <strong>商品価格上限:</strong> ¥{userInfo.subscription.itemPriceLimit}
                                            </small>
                                        ) : null}
                                    </Message>
                                }
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default CartScreen;