import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetMyProfileQuery } from "../slices/usersApiSlice";
import { setCredentials } from '../slices/authSlice';
import { toast } from "react-toastify";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import Meta from "../components/Meta";

const SubscriptionSuccessScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data: userProfile, isLoading, error } = useGetMyProfileQuery();

    useEffect(() => {
        if (userProfile) {
            dispatch(setCredentials(userProfile));
            toast.success('サブスクリプション登録が完了しました！');
        } else if (error) {
            toast.error('ユーザー情報の取得に失敗しました');
        }
    }, [userProfile, error, dispatch]);

    const handleRedirect = (path) => {
        navigate(path);
    };

    return (<>
        <Meta
            title="サブスクリプション登録完了 | ふるぷら"
            description="サブスクリプション登録が完了しました。"
            keywords={['サブスクリプション', '登録', '完了', 'ふるぷら']}
            robots='noindex, nofollow'
        />
        <Container className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
            {isLoading ? <Loader /> : (
                <Row>
                    <Col md={6} className="text-center">
                        <Card>
                            <Card.Body>
                                <Card.Title>サブスクリプション登録完了！</Card.Title>
                                <Card.Text>
                                    サブスクリプション登録が正常に完了しました。<br />
                                    以下のボタンからプロフィールまたはホームページに移動できます。
                                </Card.Text>
                                <Button variant="primary" onClick={() => handleRedirect('/profile')} className="m-2">
                                    プロフィールへ
                                </Button>
                                <Button variant="secondary" onClick={() => handleRedirect('/')} className="m-2">
                                    ホームへ
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
        </Container>
    </>);
}

export default SubscriptionSuccessScreen;