import { useSearchParams } from 'react-router-dom';
import { useMemo, useCallback } from 'react';

const useQueryParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const query = useMemo(() => {
        const obj = {};
        for (const [key, value] of searchParams.entries()) {
            obj[key] = value;
        }
        return obj;
    }, [searchParams]);

    const updateQueryParams = useCallback(
        (updates) => {
            const newSearchParams = new URLSearchParams(searchParams.toString());

            if (!Object.keys(updates).includes('page')) {
                newSearchParams.delete('page');
            }

            Object.keys(updates).forEach((key) => {
                const value = updates[key];
                if (value === null || value === undefined || value === '' || value === 'all') {
                    newSearchParams.delete(key);
                } else {
                    newSearchParams.set(key, value);
                }
            });
            setSearchParams(newSearchParams);
        },
        [searchParams, setSearchParams]
    );

    return [query, updateQueryParams];
};

export default useQueryParams;