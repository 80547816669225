import { PRODUCTS_URL, UPLOAD_URL } from "../constants/constants";
import { apiSlice } from "./apiSlice";

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query({  //builder.queryがGET、builder.mutationがPUT,POST,DELETE
            query: ({ keyword, pageNumber, gender, area, minPrice, maxPrice, sizes, colors, parentCategory, categorySlug, tag, shopId }) => ({
                url: PRODUCTS_URL,
                params: {
                    keyword,
                    pageNumber,
                    gender,
                    area,
                    minPrice,
                    maxPrice,
                    sizes,
                    colors,
                    parentCategory,
                    categorySlug,
                    tag, // タグIDが配列として渡される場合、カンマ区切りの文字列に変換
                    shopId, // ショップIDが配列として渡される場合、カンマ区切りの文字列に変換
                },
            }),
            providesTags: ["Products"], //useGetProductsQueryの引数に渡すことでキャッシュを使うことができる
            keepUnusedDataFor: 60,
        }),
        getMyShopProducts: builder.query({
            query: () => ({
                url: `${PRODUCTS_URL}/myshop`,
            }),
            providesTags: ["Products"],
            keepUnusedDataFor: 5
        }),
        getProductDetails: builder.query({
            query: (productId) => ({
                url: `${PRODUCTS_URL}/${productId}`,
            }),
            keepUnusedDataFor: 60
        }),
        createProduct: builder.mutation({
            query: () => ({
                url: PRODUCTS_URL,
                method: "POST",
            }),
            invalidatesTags: ["Products"],
        }),
        updateProduct: builder.mutation({
            query: (data) => ({
                url: `${PRODUCTS_URL}/${data.productId}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Products"], //Productsタグがついているデータをキャッシュから削除する
        }),
        uploadProductImage: builder.mutation({
            query: ({ productId, formData }) => ({
                url: `${UPLOAD_URL}/product/${productId}`,
                method: "POST",
                body: formData,
            }),
        }),
        uploadSubImages: builder.mutation({
            query: ({ productId, formData }) => ({
                url: `${UPLOAD_URL}/product/${productId}/subimages`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Products"],
        }),
        deleteProductImage: builder.mutation({
            query: (previousImage) => ({
                url: `${UPLOAD_URL}/delete`,
                method: "DELETE",
                body: previousImage,
            }),
        }),
        deleteProduct: builder.mutation({
            query: (productId) => ({
                url: `${PRODUCTS_URL}/${productId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Products"],
        }),
        getTopProducts: builder.query({
            query: () => ({
                url: `${PRODUCTS_URL}/top`,
            }),
            // providesTags: ["Products"],
            keepUnusedDataFor: 5
        }),
        addTagsToProduct: builder.mutation({
            query: ({ productId, tagIds }) => ({ 
                url: `${PRODUCTS_URL}/${productId}/addtags`,
                method: "PUT",
                body: { tagIds },
            }),
            invalidatesTags: ["Products", "Tags"],
        }),
        removeTagsFromProduct: builder.mutation({
            query: ({ productId, tagIds }) => ({
                url: `${PRODUCTS_URL}/${productId}/removetags`,
                method: "PUT",
                body: { tagIds },
            }),
            invalidatesTags: ["Products", "Tags"],
        }),
        checkoutProductAtShop: builder.mutation({
            query: ({productId}) => ({
                url: `${PRODUCTS_URL}/${productId}/checkout`,
                method: "PUT",
            }),
            invalidatesTags: ["Products"],
        }),
        cancelCheckoutProductsAtShop: builder.mutation({
            query: ({ productIds }) => ({
                url: `${PRODUCTS_URL}/myshop/cancel-checkout`,
                method: "PUT",
                body: { productIds },
            }),
            invalidatesTags: ["Products"],
        }),
    }),
});

export const { 
    useGetProductsQuery, 
    useGetMyShopProductsQuery,
    useGetProductDetailsQuery, 
    useCreateProductMutation,
    useUpdateProductMutation,
    useUploadProductImageMutation,
    useUploadSubImagesMutation,
    useDeleteProductImageMutation,
    useDeleteProductMutation,
    useGetTopProductsQuery,
    useAddTagsToProductMutation,
    useRemoveTagsFromProductMutation,
    useCheckoutProductAtShopMutation,
    useCancelCheckoutProductsAtShopMutation,
} = productsApiSlice;