import { Image, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MagazineStyleDisplay = ({ productsData }) => (
  <ul className='magazine-display'>
    {productsData.products.map(product => (
      <li key={product.id}>
        <Card className="mb-4 magazine-card">
            <Link to={`/product/${product.id}`} className='magazine-card__contents'>
                <div className='product__img-wrapper magazine-card__contents--left'>
                    <Image src={product.image} alt={product.name} loading='lazy' />
                </div>
                <div className='magazine-card__contents--right p-3'>
                    <b className='product__title'>{product.name}</b>
                    <div className='product__caption'>{product.category?.name}</div>
                    <div className='product__price-wrapper'>
                        <div className='product__price'>
                            ¥{product.price}
                        </div>
                    </div>
                </div>
            </Link>
        </Card>
      </li>
    ))}
  </ul>
);

export default MagazineStyleDisplay;