import { Col, Container, Row } from "react-bootstrap"
import Meta from "../../components/Meta"


const TermsOfService = () => {
  return (<>
    <Meta
        title='ふるぷらサービス利用規約 | ふるぷら'
        description='ふるぷらのサービス利用規約についてのページです。'
        keywords={['ふるぷら', '古着', '利用規約']}
    />
    <Container className="my-5">
        <Row className="justify-content-center">
            <Col xs={12} md={8} lg={8}>
                <h1 className="fs-3 mb-4 text-center">ふるぷらサービス利用規約</h1>
                <section>
                    <h2 className='fs-5'>第1章 総則</h2>
                    <section>
                        <h3 className='fs-6 mb-2'>第1条（目的）</h3>
                        <ol className="list-styled">
                            <li>本規約（以下、「本規約」といいます。）は、ふるぷら株式会社（以下、「当社」といいます。）が提供するインターネットショッピングおよびレンタルサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。</li>
                            <li>本規約は、本サービスの利用者（会員登録の有無を問わず、本サービスを閲覧・利用するすべての方を含み、以下「利用者」といいます。）に適用されます。</li>
                            <li>
                                当社は、以下のいずれかに該当する場合、当社の裁量により、本規約を変更できるものとします。
                                <ul className="list-styled">
                                    <li>本規約の変更が利用者の一般の利益に適合すると判断される場合</li>
                                    <li>本規約の変更が契約の目的に反せず、合理的な範囲である場合</li>
                                    <li>法令の改正等により、本規約の変更が必要となる場合</li>
                                </ul>
                            </li>
                            <li>本規約の変更を行う場合、変更後の規約の内容およびその適用開始日を、本サービス上または適切な方法で通知します。</li>
                            <li>変更後の本規約の適用開始日以降に利用者が本サービスを利用した場合、利用者は、変更後の規約に同意したものとみなされます。</li>
                            <li>本規約の一部が無効または法令に違反すると判断された場合でも、本規約の他の条項には影響を与えず、引き続き有効とします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第2条（本サービスの利用条件）</h3>
                        <ol className="list-styled">
                            <li>利用者は、本規約および当社が別途定めるプライバシーポリシー、ガイドライン、利用ルール、ヘルプ等に従い、本サービスを利用するものとします。</li>
                            <li>本サービスの一部機能を利用するためには、会員登録が必要となります。</li>
                            <li>当社は、本サービスの円滑な運営のため、利用者の本サービスにおける行動を監視し、必要に応じて利用制限や停止等の措置を講じることができます。</li>
                            <li>利用者は、本サービスの利用に際し、当社または第三者の権利を侵害しないよう十分に注意するものとします。</li>
                            <li>本サービスの利用には、インターネット接続が必要となり、その費用および通信環境の整備は利用者の負担となります。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第3条（未成年者等の利用について）</h3>
                        <ol className="list-styled">
                            <li>未成年者（18歳未満）が本サービスを利用する場合、必ず親権者または法定代理人の同意を得るものとします。</li>
                            <li>当社は、未成年者による本サービスの利用について、親権者または法定代理人の同意があったものとみなします。</li>
                            <li>成年被後見人、被保佐人および被補助人が本サービスを利用する場合、法定代理人の事前同意を必要とします。</li>
                        </ol>
                    </section>
                </section>
                <section className="mt-4">
                    <h2 className="fs-5">第2章 会員登録およびアカウント</h2>
                    <section>
                        <h3 className="fs-6 mb-2">第4条（会員登録）</h3>
                        <ol className="list-styled">
                            <li>本サービスの一部機能を利用するには、会員登録が必要となります。</li>
                            <li>会員登録を希望する者（以下、「登録希望者」といいます。）は、本規約に同意した上で、当社が指定する方法により、自らの氏名、メールアドレス、パスワード、その他当社が定める必要な情報を登録するものとします。</li>
                            <li>登録希望者は、登録に際して虚偽の情報を入力しないものとし、最新かつ正確な情報を提供するものとします。</li>
                            <li>
                                当社は、以下のいずれかに該当する場合、登録希望者の会員登録を承認しないことがあります。
                                <ul className="list-styled">
                                    <li>登録内容に虚偽、誤記、または記入漏れがあった場合</li>
                                    <li>過去に本規約違反等によりアカウントの利用停止、または会員登録の取り消しを受けたことがある場合</li>
                                    <li>その他、当社が登録を適当でないと合理的に判断した場合</li>
                                </ul>
                            </li>
                            <li>会員登録完了後、当社は会員に対して、登録されたメールアドレスに確認メールを送信するものとします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第5条（アカウントの管理）</h3>
                        <ol className="list-styled">
                            <li>会員は、自身のアカウント情報（メールアドレス、パスワード等）を自己の責任において適切に管理するものとします。</li>
                            <li>会員は、第三者に対し、自身のアカウントを貸与、譲渡、売買、承継、共有してはならないものとします。</li>
                            <li>会員は、アカウントの不正利用が発覚した場合、またはアカウントの安全性に問題が生じる恐れがある場合、直ちに当社に通知するものとします。</li>
                            <li>当社は、会員のアカウントが不正に使用されたことにより会員に生じた損害について、当社の故意または重過失による場合を除き、一切の責任を負いません。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第6条（登録内容の変更）</h3>
                        <p>会員は、登録内容（氏名、メールアドレス、住所、電話番号等）に変更が生じた場合、速やかに当社の定める方法により変更手続きを行うものとします。変更手続きを行わなかったことにより会員に生じた損害について、当社は一切の責任を負いません。</p>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第7条（退会）</h3>
                        <ol className="list-styled">
                            <li>会員は、当社の定める手続きに従い、いつでも本サービスから退会することができます。</li>
                            <li>退会により、会員は本サービスの全機能を利用できなくなり、すべてのデータおよびポイント等が消滅する場合があります。消滅したデータやポイント等について、当社は一切の補償を行いません。</li>
                            <li>退会後も、会員が本サービスの利用中に行った行為に関する責任は存続するものとします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第8条（会員資格の停止および取り消し）</h3>
                        <ol className="list-styled">
                            <li>
                                当社は、会員が以下のいずれかに該当すると判断した場合、事前の通知なく、当該会員の本サービスの利用停止または会員登録の取り消しを行うことができます。
                                <ul className="list-styled">
                                    <li>本規約に違反した場合</li>
                                    <li>登録時に虚偽の情報を提供したことが判明した場合</li>
                                    <li>当社または第三者に対し、不利益または損害を与える行為を行った場合</li>
                                    <li>不正アクセス、詐欺行為、その他違法行為を行った場合</li>
                                    <li>その他、当社が会員として不適当と合理的に判断した場合</li>
                                </ul>
                            </li>
                            <li>会員資格の停止または取り消しにより、当社が会員に対して何らかの損害を与えた場合でも、当社は一切の責任を負いません。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第9条（アカウントの統合および削除）</h3>
                        <ol className="list-styled">
                            <li>当社は、複数のアカウントを作成した会員に対し、アカウントの統合を求めることがあります。</li>
                            <li>長期間利用されていないアカウントについて、当社の判断により削除することがあります。</li>
                            <li>アカウントの統合または削除に伴い生じた不利益について、当社は一切の責任を負いません。</li>
                        </ol>
                    </section>
                </section>
                <section className="mt-4">
                    <h2 className="fs-5">第3章 商品の購入およびレンタル</h2>
                    <section>
                        <h3 className="fs-6 mb-2">第10条（商品・レンタルの購入・申し込み）</h3>
                        <ol className="list-styled">
                            <li>会員は、本サービスを通じて当社または出店者から商品を購入またはレンタルすることができます。</li>
                            <li>会員は、購入・レンタルを希望する商品を本サービス上で選択し、当社の指定する方法に従って申し込みを行うものとします。</li>
                            <li>申し込み完了後、当社または出店者から注文確認の通知を行い、当該通知が会員に到達した時点で売買契約またはレンタル契約が成立するものとします。</li>
                            <li>本サービス上での申し込み手続きにおいて、会員が誤った情報を入力したことにより生じた損害について、当社は一切の責任を負いません。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第11条（価格および支払い方法）</h3>
                        <ol className="list-styled">
                            <li>商品の価格およびレンタル料金は、本サービス上に表示された金額とします。ただし、表示価格に誤りがあった場合、当社は注文をキャンセルすることができるものとします。</li>
                            <li>
                                会員は、以下のいずれかの方法で支払いを行うものとします。
                                <ul className="list-styled">
                                    <li>クレジットカード決済</li>
                                    <li>電子マネー決済</li>
                                    <li>銀行振込</li>
                                    <li>その他当社が認める支払い方法</li>
                                </ul>
                            </li>
                            <li>クレジットカード決済の場合、会員はクレジットカード会社の定める利用規約に従うものとします。</li>
                            <li>支払いが確認できない場合、当社は契約を解除し、商品・レンタルの提供を行わないことができます。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第12条（商品の所有権およびレンタル品の返却）</h3>
                        <ol className="list-styled">
                            <li>購入した商品の所有権および危険負担は、当社または出店者が商品を配送業者に引き渡した時点で会員に移転するものとします。</li>
                            <li>レンタル品については、契約期間満了までに当社の指定する方法で返却するものとします。</li>
                            <li>会員がレンタル品の返却を遅延した場合、当社は遅延料金を請求することができます。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第13条（配送および受け取り）</h3>
                        <ol className="list-styled">
                            <li>商品は、会員が指定した住所に配送されます。ただし、日本国内のみに限定される場合があります。</li>
                            <li>配送業者の事情、天候、災害などの不可抗力により遅延が発生した場合、当社は一切の責任を負いません。</li>
                            <li>会員が受け取りを拒否した場合、または長期間不在で商品を受け取らなかった場合、当社は当該注文をキャンセルし、返金を行わないことがあります。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第14条（返品・交換・キャンセル）</h3>
                        <ol className="list-styled">
                            <li>
                                会員は、以下の場合に限り、返品・交換を申し込むことができます。
                                <ul className="list-styled">
                                    <li>商品に初期不良があった場合</li>
                                    <li>申し込み内容と異なる商品が届いた場合</li>
                                </ul>
                            </li>
                            <li>返品・交換の申し込みは、商品到着後7日以内に行うものとします。</li>
                            <li>
                                以下の場合、返品・交換は受け付けられません。
                                <ul className="list-styled">
                                    <li>商品が使用済みである場合</li>
                                    <li>タグや付属品が紛失している場合</li>
                                    <li>衛生商品（下着、水着、化粧品など）である場合</li>
                                </ul>
                            </li>
                            <li>会員の都合による返品・交換は原則として受け付けません。</li>
                            <li>購入・レンタルともに注文確定後のキャンセルは原則としてできません。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第15条（レンタル商品の管理および損害賠償）</h3>
                        <ol className="list-styled">
                            <li>会員は、レンタル品を適切に管理し、汚損・破損しないように注意を払うものとします。</li>
                            <li>レンタル品に重大な破損、紛失、盗難が発生した場合、会員は当社の定める賠償金を支払うものとします。</li>
                            <li>通常の使用による軽微な汚れや劣化については、会員の責任を問わないものとします。</li>
                        </ol>
                    </section>
                </section>
                <section className="mt-4">
                    <h2 className="fs-5">第4章 サービスの利用および禁止事項</h2>
                    <section>
                        <h3 className="fs-6 mb-2">第16条（サービスの利用）</h3>
                        <ol className="list-styled">
                            <li>会員は、本規約および当社が定める各種ガイドラインに従い、本サービスを利用するものとします。</li>
                            <li>本サービスの提供範囲は日本国内に限られます。ただし、当社が特に認めた場合には、この限りではありません。</li>
                            <li>当社は、サービスの提供に際し、定期的なシステムメンテナンス、法令の改正、その他の事由により、事前の通知なくサービスの内容を変更、停止、または終了することができます。</li>
                            <li>会員が未成年者である場合、親権者または法定代理人の同意を得た上でサービスを利用するものとします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第17条（禁止事項）</h3>
                        <p>会員は、本サービスの利用に際し、以下の行為を行ってはならないものとします。</p>
                        <ol className="list-styled">
                            <li>
                                不正行為・迷惑行為
                                <ul className="list-styled">
                                    <li>他の会員または第三者に対する誹謗中傷、嫌がらせ、脅迫行為</li>
                                    <li>他の会員になりすます行為</li>
                                    <li>本サービスの運営を妨害する行為</li>
                                    <li>不正アクセス、ハッキング行為</li>
                                    <li>他人の個人情報を不正に収集または使用する行為</li>
                                </ul>
                            </li>
                            <li>
                                法令違反行為
                                <ul className="list-styled">
                                    <li>法令、公序良俗に反する行為</li>
                                    <li>犯罪行為またはそれを助長する行為</li>
                                    <li>知的財産権、肖像権、プライバシー権を侵害する行為</li>
                                    <li>虚偽または誤解を招く情報の提供</li>
                                </ul>
                            </li>
                            <li>
                                転売・商業目的の利用
                                <ul className="list-styled">
                                    <li>商品・レンタル品を転売目的で購入またはレンタルする行為</li>
                                    <li>本サービスを利用して営業、広告、勧誘を行う行為</li>
                                    <li>本サービスのシステムを利用した違法な取引行為</li>
                                </ul>
                            </li>
                            <li>
                                不適切な利用
                                <ul className="list-styled">
                                    <li>返品・交換ポリシーを悪用し、過度に注文キャンセルや返品を行う行為</li>
                                    <li>不正な決済手段を使用する行為</li>
                                    <li>当社が不適切と判断するその他の行為</li>
                                </ul>
                            </li>
                            <li>
                                その他
                                <ul className="list-styled">
                                    <li>当社または第三者の権利・利益を損なう行為</li>
                                    <li>本規約に違反する行為</li>
                                    <li>その他、当社が不適切と判断する行為</li>
                                </ul>
                            </li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第18条（違反行為への対応）</h3>
                        <ol className="list-styled">
                            <li>
                                当社は、会員が本規約に違反した場合、以下の措置を講じることができます。
                                <ul className="list-styled">
                                    <li>サービスの一時停止または永久利用禁止</li>
                                    <li>会員資格の取消し</li>
                                    <li>過去の取引の調査</li>
                                    <li>関係機関への通報</li>
                                </ul>
                            </li>
                            <li>上記措置により会員が被った損害について、当社は一切の責任を負いません。</li>
                            <li>会員が本規約違反により当社または第三者に損害を与えた場合、会員はその損害を賠償するものとします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第19条（アカウント管理）</h3>
                        <ol className="list-styled">
                            <li>会員は、自己の責任においてアカウント情報（IDおよびパスワード）を管理するものとし、第三者に譲渡、貸与、共有してはなりません。</li>
                            <li>会員のIDおよびパスワードが第三者に使用され、損害が発生した場合、当社は一切の責任を負いません。</li>
                            <li>アカウント情報が不正に使用されていることが判明した場合、会員は直ちに当社に通知し、指示に従うものとします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第20条（サービスの変更・中断・終了）</h3>
                        <ol className="list-styled">
                            <li>
                                当社は、以下の理由により、本サービスの全部または一部を変更、中断、または終了することができます。
                                <ul className="list-styled">
                                    <li>サービスの維持・改善のための定期メンテナンス</li>
                                    <li>天災、事故、停電、通信障害などの不可抗力</li>
                                    <li>法令の変更または行政機関の指示</li>
                                    <li>その他、当社が必要と判断した場合</li>
                                </ul>
                            </li>
                            <li>本サービスの変更、中断、終了により会員に損害が生じた場合、当社は一切の責任を負わないものとします。</li>
                        </ol>
                    </section>
                </section>
                <section className="mt-4">
                    <h2 className="fs-5">第5章 取引に関する特約</h2>
                    <section>
                        <h3 className="fs-6 mb-2">第21条（商品の購入およびレンタル）</h3>
                        <ol className="list-styled">
                            <li>会員は、本サービスを利用して、当社が提供する商品を購入またはレンタルすることができます。</li>
                            <li>商品の購入およびレンタルの申込みは、会員が本サービスの注文確定画面で確定ボタンを押し、当社が注文確認の通知を送信した時点で成立するものとします。</li>
                            <li>商品の所有権およびリスク（破損・紛失など）は、当社または出品店が配送業者に商品を引き渡した時点または店舗にて会員が受領した時点で会員に移転します。</li>
                            <li>一部の商品については、購入またはレンタルが制限される場合があります。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第22条（支払い方法）</h3>
                        <ol className="list-styled">
                            <li>
                                商品の代金およびレンタル料金は、以下のいずれかの方法で支払うものとします。
                                <ul className="list-styled">
                                    <li>クレジットカード決済</li>
                                    <li>電子マネー決済</li>
                                    <li>電子マネー決済</li>
                                    <li>その他当社が定める支払い方法</li>
                                </ul>
                            </li>
                            <li>クレジットカード決済を利用する場合、会員は自身の名義のクレジットカードを使用するものとします。クレジットカードの利用に関しては、会員とクレジットカード会社の契約に基づきます。</li>
                            <li>代金支払いが確認できない場合、当社は注文をキャンセルすることができます。</li>
                            <li>分割払い、後払いなどの決済手段を利用する場合は、会員が決済代行会社の規約に従うものとします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第23条（配送および受け取り）</h3>
                        <ol className="list-styled">
                            <li>当社は、会員が指定した住所に対して、当社または提携する配送業者を通じて商品を配送します。</li>
                            <li>配送先は日本国内に限ります。ただし、当社が特に認めた場合を除きます。</li>
                            <li>配送に要する日数は、商品ごとに異なり、当社が提示する目安を基準としますが、天候、交通事情、その他の事情により遅延する場合があります。</li>
                            <li>会員は、配送業者が指定する方法に従って商品を受け取るものとします。</li>
                            <li>会員が受け取りを拒否した場合、または長期不在により配送が完了しない場合、当社は商品の再送、キャンセル、および会員への費用請求を行うことがあります。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第24条（返品・交換・キャンセル）</h3>
                        <ol className="list-styled">
                            <li>
                                会員は、以下の場合に限り、商品を返品または交換することができます。
                                <ul className="list-styled">
                                    <li>届いた商品が注文内容と異なる場合</li>
                                    <li>商品に瑕疵（破損・汚損・欠陥）がある場合</li>
                                    <li>その他、当社が特別に返品・交換を認めた場合</li>
                                </ul>
                            </li>
                            <li>返品・交換の申請は、商品到着後7日以内に行うものとし、当社が定める方法で手続きを行う必要があります。</li>
                            <li>返品・交換が認められる場合、送料は当社が負担します。ただし、会員都合による返品・交換の場合は、送料は会員の負担となります。</li>
                            <li>
                                次のいずれかに該当する場合、返品・交換は受け付けません。
                                <ul className="list-styled">
                                    <li>商品を一度使用した場合</li>
                                    <li>商品タグ・ラベル・付属品が紛失している場合</li>
                                    <li>衣類などの商品に臭い・汚れ・傷がついている場合</li>
                                    <li>その他、当社が返品・交換不可と判断する場合</li>
                                </ul>
                            </li>
                            <li>注文確定後のキャンセルは、原則として受け付けません。ただし、特別な事情がある場合、当社の判断により対応することがあります。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第25条（レンタル商品の返却および延滞料）</h3>
                        <ol className="list-styled">
                            <li>会員は、レンタル期間が終了するまでに、当社が指定する方法でレンタル商品を返却するものとします。</li>
                            <li>
                                返却時に以下のような問題が発生した場合、会員は追加料金を負担するものとします。
                                <ul className="list-styled">
                                    <li>延滞：レンタル期間を超過した場合、当社が定める延滞料を請求します。</li>
                                    <li>汚損・破損：商品に通常の使用範囲を超える汚損・破損がある場合、修理費または商品代金の全額を請求します。</li>
                                    <li>紛失：商品を紛失した場合、商品代金の全額を請求します。</li>
                                </ul>
                            </li>
                            <li>会員が延滞料、修理費、商品代金の請求に応じない場合、当社は法的措置を取ることがあります。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第26条（所有権および知的財産権）</h3>
                        <ol className="list-styled">
                            <li>商品の所有権は、購入商品については当社が配送業者に引き渡した時点で会員に移転します。</li>
                            <li>レンタル商品は当社または出店者の所有物であり、会員は契約期間内でのみ使用することができます。</li>
                            <li>本サービス内のコンテンツ（商品情報、画像、デザインなど）の著作権および知的財産権は当社または正当な権利を有する第三者に帰属します。</li>
                            <li>会員は、当社のコンテンツを無断で複製、転用、転載、配布、改変しないものとします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第27条（免責事項）</h3>
                        <ol className="list-styled">
                            <li>
                                当社は、次のいずれかに該当する場合、責任を負いません。
                                <ul className="list-styled">
                                    <li>会員の利用環境（インターネット接続不良、デバイスの不具合など）に起因する本サービスの遅延または停止</li>
                                    <li>第三者による不正アクセス、データ改ざん、ウイルス感染などにより生じた損害</li>
                                    <li>天災、地震、火災、停電、戦争、テロ行為など不可抗力による損害</li>
                                    <li>会員の過失または規約違反によって生じた損害</li>
                                </ul>
                            </li>
                            <li>商品の品質、機能、適合性については、当社の故意または重大な過失がある場合を除き、当社は一切の責任を負いません。</li>
                            <li>本サービスの情報（商品説明、価格、在庫状況など）は、常に正確であるよう努めますが、誤記や変更が生じる場合があります。これにより生じた損害について、当社は責任を負いません。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第28条（取引に関するトラブル）</h3>
                        <p>会員と第三者（配送業者、決済代行会社、他の会員など）との間で紛争が発生した場合、当社は関与せず、当事者間で解決するものとします。ただし、当社が必要と判断した場合、事実関係の確認および適切な対応を行うことがあります。</p>
                    </section>
                </section>
                <section className="mt-4">
                    <h2 className="fs-5">第6章 退会および会員資格の喪失</h2>
                    <section>
                        <h3 className="fs-6 mb-2">第29条（退会手続）</h3>
                        <ol className="list-styled">
                            <li>会員は、未返却のレンタル商品がある場合や未払い金があるなど、本規約に基づく責任が果たされていない状態で本サービスから退会することはできません。それ以外の場合、会員は、当社が指定する方法に従い、いつでも本サービスから退会することができます。</li>
                            <li>退会の申請が完了した時点で、会員資格は失効し、以降、本サービスの利用はできなくなります。</li>
                            <li>退会後も、退会前の利用に基づく未払い金の支払い義務や、本規約に基づく責任は免除されません。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第30条（会員資格の喪失および利用停止）</h3>
                        <ol className="list-styled">
                            <li>
                                当社は、会員が以下のいずれかに該当すると判断した場合、事前通知なしに本サービスの利用停止、または会員資格の取消を行うことができます。
                                <ul className="list-styled">
                                    <li>会員が本規約に違反した場合</li>
                                    <li>会員が支払いを怠り、または繰り返し未払いを行った場合</li>
                                    <li>会員が不正行為（詐欺行為、なりすまし、決済手段の不正利用など）を行った場合</li>
                                    <li>会員が第三者に本サービスのアカウントを貸与、譲渡、売買した場合</li>
                                    <li>申請時に虚偽の情報を提供していた場合</li>
                                    <li>他の会員または当社に対して、迷惑行為や業務妨害を行った場合</li>
                                    <li>当社が会員として不適格と判断した場合</li>
                                </ul>
                            </li>
                            <li>会員資格が取り消された場合、会員は本サービスを利用する権利を失い、以降の再登録が認められない場合があります。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第31条（退会および会員資格喪失後の取り扱い）</h3>
                        <ol className="list-styled">
                            <li>
                                会員が退会または会員資格を喪失した場合、以下の条件が適用されます。
                                <ul className="list-styled">
                                    <li>未使用のポイント、クーポン、割引権は全て無効となります。</li>
                                    <li>未払いの債務（料金未納、延滞金など）は引き続き支払う義務があります。</li>
                                    <li>過去の取引履歴は、当社の定める期間保存されますが、本人のアクセスはできなくなります。</li>
                                    <li>退会後のデータの削除・変更は原則としてできません。</li>
                                </ul>
                            </li>
                            <li>退会または会員資格喪失後も、当社は個人情報保護方針に基づき、法令に従って一定期間会員情報を保管します。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第32条（利用停止または会員資格取消に伴う責任）</h3>
                        <p>当社は、会員の利用停止または会員資格取消によって生じた損害について、一切の責任を負いません。会員資格が取り消された場合でも、当社が必要と判断した場合、法的措置を取ることがあります。</p>
                    </section>
                </section>
                <section className="mt-4">
                    <h2 className="fs-5">第7章 禁止事項および免責事項</h2>
                    <section>
                        <h3 className="fs-6 mb-2">第33条（禁止事項）</h3>
                        <p>会員は、本サービスを利用するにあたり、以下の行為を行ってはなりません。</p>
                        <ol className="list-styled">
                            <li>
                                不正行為
                                <ul className="list-styled">
                                    <li>虚偽の情報を登録する行為</li>
                                    <li>他人のアカウントを不正に使用する行為</li>
                                    <li>クレジットカードや決済手段を不正に使用する行為</li>
                                    <li>複数アカウントの不正作成・利用</li>
                                </ul>
                            </li>
                            <li>
                                営業妨害および迷惑行為
                                <ul className="list-styled">
                                    <li>他の会員や第三者に対する誹謗中傷、脅迫、嫌がらせ行為</li>
                                    <li>当社、会員、または第三者の権利・利益を侵害する行為</li>
                                    <li>システムやサービスの正常な運営を妨害する行為</li>
                                </ul>
                            </li>
                            <li>
                                不適切なコンテンツの投稿・送信
                                <ul className="list-styled">
                                    <li>違法または公序良俗に反するコンテンツの投稿</li>
                                    <li>差別的、侮辱的、暴力的、わいせつな表現の投稿</li>
                                    <li>第三者の著作権、商標権、その他の知的財産権を侵害する行為</li>
                                </ul>
                            </li>
                            <li>
                                不正な取引・サービスの利用
                                <ul className="list-styled">
                                    <li>転売、転貸、または商品購入の意思がない取引</li>
                                    <li>虚偽の返品、返金請求</li>
                                    <li>ポイントやクーポンの不正取得・不正使用</li>
                                </ul>
                            </li>
                            <li>
                                システムの不正利用
                                <ul className="list-styled">
                                    <li>当社のサーバー、ネットワークに過度な負担をかける行為</li>
                                    <li>ウイルス、マルウェアなどの有害なプログラムを配布する行為</li>
                                    <li>クローリング、スクレイピング、その他の不正アクセス行為</li>
                                </ul>
                            </li>
                            <li>
                                その他の違反行為
                                <ul className="list-styled">
                                    <li>法令、規則、本規約、当社のガイドラインに違反する行為</li>
                                    <li>反社会的勢力との関係がある、または関与する行為</li>
                                    <li>その他、当社が不適切と判断する行為</li>
                                </ul>
                            </li>
                        </ol>
                        <p>当社は、会員が本条のいずれかに違反したと判断した場合、会員資格の停止、取消し、法的措置などを講じることができます。</p>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第34条（免責事項）</h3>
                        <ol className="list-styled">
                            <li>
                                本サービスの提供に関する免責
                                <ul className="list-styled">
                                    <li>当社は、技術的・運営上の理由により、本サービスの全部または一部を事前の通知なしに変更、中断、終了することがあります。</li>
                                    <li>当社は、本サービスの中断や変更によって生じた損害について、一切責任を負いません。</li>¥
                                </ul>
                            </li>
                            <li>
                                取引に関する免責
                                <ul className="list-styled">
                                    <li>当社は、会員と販売者または他の会員間の取引について、一切の保証をしません。</li>
                                    <li>商品の品質、適法性、正確性、完全性について、当社は責任を負いません。</li>
                                </ul>
                            </li>
                            <li>
                                通信環境およびシステム障害に関する免責
                                <ul className="list-styled">
                                    <li>当社は、会員の通信環境やデバイスの不具合に起因する本サービスの利用不能について責任を負いません。</li>
                                    <li>本サービスの遅延、停止、データ消失、情報の誤送信・漏洩等について、当社は一切責任を負いません。</li>
                                </ul>
                            </li>
                            <li>
                                第三者の行為に関する免責
                                <ul className="list-styled">
                                    <li>他の会員や第三者による行為（不正アクセス、詐欺、情報の改ざん等）について、当社は責任を負いません。</li>
                                </ul>
                            </li>
                            <li>
                                不可抗力に関する免責
                                <ul className="list-styled">
                                    <li>天災、戦争、テロ、法改正、政府の指示、停電、通信障害等の不可抗力による損害について、当社は責任を負いません。</li>
                                </ul>
                            </li>
                            <li>
                                その他の免責
                                <ul className="list-styled">
                                    <li>当社の責に帰すべき事由によらない損害について、当社は一切の責任を負いません。</li>
                                    <li>会員が本サービスを利用することにより生じた損害について、当社の故意または重過失による場合を除き、当社の責任は本サービスに関連して会員が当社に支払った金額を上限とします。</li>
                                </ul>
                            </li>
                        </ol>
                    </section>
                </section>
                <section className="mt-4">
                    <h2 className="fs-5">第8章 知的財産権およびその他の規定</h2>
                    <section>
                        <h3 className="fs-6 mb-2">第35条（知的財産権）</h3>
                        <ol className="list-styled">
                            <li>本サービス上のコンテンツ（テキスト、画像、動画、デザイン、ロゴ、プログラム、データベース等）に関する著作権、商標権、特許権、その他の知的財産権は、当社または正当な権利を有する第三者に帰属します。</li>
                            <li>会員は、当社の許可なく、本サービスのコンテンツを複製、改変、転載、再配布、公衆送信、販売、翻訳、二次利用することはできません。</li>
                            <li>会員が本サービスを利用して投稿・アップロードしたコンテンツ（商品レビュー、コメント、画像等）の著作権は、会員に帰属します。ただし、会員は当社に対し、当該コンテンツを非独占的かつ無償で使用（編集、公開、複製、配信等を含む）する権利を許諾するものとします。</li>
                            <li>会員は自身が投稿・アップロードしたコンテンツについて、第三者の権利を侵害しないことを保証するものとし、第三者との間で紛争が生じた場合、会員は自己の責任と費用で解決するものとします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第36条（本規約の変更）</h3>
                        <ol className="list-styled">
                            <li>
                                当社は、以下の場合に本規約を変更することができるものとします。
                                <ul className="list-styled">
                                    <li>本規約の変更が、会員の一般の利益に適合すると判断される場合。</li>
                                    <li>本規約の変更が、契約の目的に反せず、かつ、変更の必要性、内容の相当性を考慮したうえで合理的である場合。</li>
                                </ul>
                            </li>
                            <li>本規約を変更する場合、変更後の規約の内容および適用開始日を、本サービスのウェブサイト上での告知、または適切な方法により事前に通知します。</li>
                            <li>変更後の規約の適用開始日以降に会員が本サービスを利用した場合、会員は変更後の規約に同意したものとみなします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第37条（契約の譲渡）</h3>
                        <ol className="list-styled">
                            <li>会員は、当社の書面による事前の承諾なしに、本規約に基づく権利および義務を第三者に譲渡、移転、承継、担保提供することはできません。</li>
                            <li>当社は、本サービスの提供に関する事業を第三者に譲渡した場合、譲渡に伴い本規約に基づく権利および義務も譲渡されるものとし、会員はこれに同意するものとします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第38条（準拠法および管轄裁判所）</h3>
                        <ol className="list-styled">
                            <li>本規約の準拠法は、日本法とします。</li>
                            <li>本サービスに関して会員と当社の間で生じた紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</li>
                        </ol>
                    </section>
                    <section>
                        <h3 className="fs-6 mb-2">第39条（協議解決）</h3>
                        <p>本規約に定めのない事項または解釈について疑義が生じた場合、当社および会員は誠意をもって協議し、解決を図るものとします。</p>
                    </section>
                </section>
                <section>
                    <h3 className="fs-6 mb-2">附則</h3>
                    <p>本規約は2025年2月1日から全ての利用者に適用されるものとします。</p>
                </section>
            </Col>
        </Row>
    </Container>
    </>)
}

export default TermsOfService