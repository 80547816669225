import { useState, useEffect } from 'react';
import { COLOR } from '../../../constants/productConstants';

const ColorFilter = ({ query, updateQueryParams }) => {
    const queryColors = query.color ? query.color.split('-') : [];

    const [localColors, setLocalColors] = useState(queryColors);

    useEffect(() => {
        setLocalColors(queryColors);
    }, [query.color]);

    const handleToggleColor = (key) => {
        setLocalColors((prev) =>
            prev.includes(key)
                ? prev.filter((item) => item !== key)
                : [...prev, key]
        );
    };

    const handleApply = () => {
        updateQueryParams({
            color: localColors.length ? localColors.join('-') : null,
        });
    };

    const handleClear = () => {
        setLocalColors([]);
        updateQueryParams({ color: null });
    };

    const colorNameToStyle = {
        'ホワイト': 'white',
        'ブラック': 'black',
        'グレー': 'gray',
        'ブラウン': 'brown',
        'ベージュ': 'beige',
        'グリーン': 'green',
        'ブルー': 'blue',
        'パープル': 'purple',
        'イエロー': 'yellow',
        'ピンク': 'pink',
        'レッド': 'red',
        'オレンジ': 'orange',
        'シルバー': 'silver',
        'ゴールド': 'gold',
        'その他': '#ccc',
    };

    return (<>
        <div className='color-filter'>
            {Object.entries(COLOR).map(([key, label]) => {
                const isSelected = localColors.includes(key);
                const backgroundColor = colorNameToStyle[label] || '#ccc';
                return (
                    <div
                        key={key}
                        className={`color-filter__button ${isSelected ? 'color-filter__button--active' : ''}`}
                        style={{
                            backgroundColor,
                            cursor: 'pointer',
                            ...(backgroundColor === 'white' && { border: '1px solid #ccc' })
                        }}
                        onClick={() => handleToggleColor(key)}
                    />
                );
            })}
        </div>
        <div className="filter-sidebar__buttons">
            <button className="filter-sidebar__button filter-sidebar__button--apply" onClick={handleApply}>OK</button>
            <button className="filter-sidebar__button filter-sidebar__button--clear" onClick={handleClear}>リセット</button>
        </div>
    </>);
};

export default ColorFilter;