import { Route } from 'react-router-dom';

import App from './App';
import HomeScreen from './screens/HomeScreen';
import SearchScreen from './screens/SearchScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import EmailVerifyScreen from './screens/EmailVerifyScreen';
import SubscriptionScreen from './screens/SubscriptionScreen';
import SubscriptionCheckoutScreen from './screens/SubscriptionCheckoutScreen';
import PaymentScreen from './screens/PaymentScreen';
import OrderCompleteScreen from './screens/order/OrderCompleteScreen';
import PlaceOrderScreen from './screens/order/PlaceOrderScreen';
import OrderScreen from './screens/order/OrderScreen';
import ShippingScreen from './screens/order/ShippingScreen';
import ProfileScreen from './screens/ProfileScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import FavoriteScreen from './screens/FavoriteScreen';
import UserApprovalRequestScreen from './screens/UserApprovalRequestScreen';
import NotificationScreen from './screens/NotificationScreen';
import ShopListScreen from './screens/ShopListScreen';
import ShopHomeScreen from './screens/ShopHomeScreen';
import SubscriptionSuccessScreen from './screens/SubscriptionSuccessScreen';
import CancellationReturnPolicy from './screens/help/CancellationReturnPolicy';
import LegalNotice from './screens/help/LegalNotice';
import PrivacyPolicy from './screens/help/PrivacyPolicy';
import TermsOfService from './screens/help/TermsOfService';

// プライベートルート例 (サイトマップには含めない想定なら後でフィルタ)
import PrivateRoute from './components/PrivateRoute';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from './constants/constants';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const routes = (
    <Route path='/' element={<App />}>
        <Route index={true} path='/' element={<HomeScreen />} />
        <Route path='/search' element={<SearchScreen />} />
        <Route path='/category' element={<SearchScreen />} />
        <Route path='/category/:parentCategory' element={<SearchScreen />} />
        <Route path='/category/:parentCategory/:category' element={<SearchScreen />} />
        <Route path='/product/:id' element={<ProductScreen />} />
        <Route path='/cart' element={<CartScreen />} />
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/register' element={<RegisterScreen />} />
        <Route path='/verify-email' element={<EmailVerifyScreen />} />
        <Route path='/subscriptions' element={<SubscriptionScreen />} />
        <Route path='/shops' element={<ShopListScreen />} />
        <Route path='/shop/:slug' element={<ShopHomeScreen />} />
        <Route path='/shop/:slug/about' element={<ShopHomeScreen />} />
        <Route path='/shop/:slug/contact' element={<ShopHomeScreen />} />
        <Route path='/notifications' element={<NotificationScreen />} />

        <Route  path='' element={<PrivateRoute />}>
            <Route path='/subscription/:id/join' element={<SubscriptionCheckoutScreen />} />
            <Route  path='/shipping' element={<ShippingScreen />} />
            <Route  path='/payment' element={<PaymentScreen />} />
            <Route  path='/placeorder' element={<PlaceOrderScreen />} />
            <Route path='/order/:id' element={
            <Elements stripe={stripePromise}>
                <OrderScreen />
            </Elements>
            } />
            <Route  path='/order-complete' element={<OrderCompleteScreen />} />
            <Route  path='/profile' element={<ProfileScreen />} />
            <Route  path='/order-history' element={<OrderHistoryScreen />} />
            <Route  path='/favorites' element={<FavoriteScreen />} />
            <Route path='/user-approval-request/:id' element={<UserApprovalRequestScreen />} />
            <Route path='/subscription-success' element={<SubscriptionSuccessScreen />} />
        </Route>

        <Route path='/help/cancellation-return-policy' element={<CancellationReturnPolicy/>} />
        <Route path='/help/legal-notice' element={<LegalNotice/>} />
        <Route path='/help/privacy-policy' element={<PrivacyPolicy/>} />
        <Route path='/help/terms-of-service' element={<TermsOfService/>} />

        <Route path='*' element={<h1>Not Found</h1>} />
    </Route>
)

export default routes;