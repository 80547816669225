import { useState, useEffect } from "react";

const MIN_LIMIT = 0;
const MAX_LIMIT = 1000000;
const PRICE_GAP = 500;

/**
 * sliderValueToPrice : スライダーの正規化された値 (0～100) から実際の価格に変換する関数
 */
const sliderValueToPrice = (s) => {
  if (s <= 50) {
    // セグメント A: 0～50 => 0～20,000 円（1000 円単位）
    let raw = (s / 50) * 20000;
    return Math.round(raw / 1000) * 1000;
  } else if (s <= 60) {
    // セグメント B: 50～60 => 20,000～50,000 円（5000 円単位）
    let raw = ((s - 50) / 10) * 30000 + 20000;
    return Math.round(raw / 5000) * 5000;
  } else if (s <= 70) {
    // セグメント C: 60～70 => 50,000～100,000 円（10000 円単位）
    let raw = ((s - 60) / 10) * 50000 + 50000;
    return Math.round(raw / 10000) * 10000;
  } else if (s <= 85) {
    // セグメント D: 70～85 => 100,000～500,000 円（100000 円単位）
    let raw = ((s - 70) / 15) * 400000 + 100000;
    return Math.round(raw / 100000) * 100000;
  } else {
    // セグメント E: 85～100 => 500,000～1,000,000 円（250000 円単位）
    let raw = ((s - 85) / 15) * 500000 + 500000;
    return Math.round(raw / 250000) * 250000;
  }
};

/**
 * priceToSliderValue : 実際の価格からスライダーの正規化された値 (0～100) に変換する関数
 */
const priceToSliderValue = (price) => {
  if (price <= 20000) {
    return (price / 20000) * 50;
  } else if (price <= 50_000) {
    return 50 + ((price - 20000) / 30000) * 10;
  } else if (price <= 100000) {
    return 60 + ((price - 50000) / 50000) * 10;
  } else if (price <= 500000) {
    return 70 + ((price - 100000) / 400000) * 15;
  } else {
    return 85 + ((price - 500000) / 500000) * 15;
  }
};

const PriceFilter = ({ query, updateQueryParams }) => {
  const [inputMin, setInputMin] = useState(query.minPrice ? String(query.minPrice) : "");
  const [inputMax, setInputMax] = useState(query.maxPrice ? String(query.maxPrice) : "");

  // スライダー状態は 0～100 の正規化された値で管理
  const [sliderMin, setSliderMin] = useState(
    query.minPrice ? priceToSliderValue(parseInt(query.minPrice, 10)) : 0
  );
  const [sliderMax, setSliderMax] = useState(
    query.maxPrice ? priceToSliderValue(parseInt(query.maxPrice, 10)) : 100
  );

  useEffect(() => {
    setInputMin(query.minPrice ? String(query.minPrice) : "");
    setInputMax(query.maxPrice ? String(query.maxPrice) : "");
    setSliderMin(query.minPrice ? priceToSliderValue(parseInt(query.minPrice, 10)) : 0);
    setSliderMax(query.maxPrice ? priceToSliderValue(parseInt(query.maxPrice, 10)) : 100);
  }, [query.minPrice, query.maxPrice]);

  // スライダー変更時のハンドラ
  const handleSliderMinChange = (e) => {
    const newSliderMin = parseFloat(e.target.value);
    const newPrice = sliderValueToPrice(newSliderMin);
    const currentMaxPrice = sliderValueToPrice(sliderMax);
    // gap バリデーション
    if (currentMaxPrice - newPrice < PRICE_GAP) {
      return;
    }
    setSliderMin(newSliderMin);
    setInputMin(String(sliderValueToPrice(newSliderMin)));
  };

  const handleSliderMaxChange = (e) => {
    const newSliderMax = parseFloat(e.target.value);
    const newPrice = sliderValueToPrice(newSliderMax);
    const currentMinPrice = sliderValueToPrice(sliderMin);
    if (newPrice - currentMinPrice < PRICE_GAP) {
      return;
    }
    setSliderMax(newSliderMax);
    setInputMax(String(sliderValueToPrice(newSliderMax)));
  };

  // 入力欄変更時のハンドラ（文字列として保持）
  const handleInputMinChange = (e) => {
    setInputMin(e.target.value);
  };

  const handleInputMaxChange = (e) => {
    setInputMax(e.target.value);
  };

  // 入力欄の onBlur で数値を正規化し、スライダーと同期＋バリデーション
  const handleMinInputBlur = () => {
    let price = parseInt(inputMin, 10);
    if (isNaN(price)) {
      price = MIN_LIMIT;
    }
    if (price < MIN_LIMIT) {
      price = MIN_LIMIT;
    }
    const currentMaxPrice = sliderValueToPrice(sliderMax);
    if (price > currentMaxPrice - PRICE_GAP) {
      price = currentMaxPrice - PRICE_GAP;
    }
    const newSliderValue = priceToSliderValue(price);
    setSliderMin(newSliderValue);
    setInputMin(String(sliderValueToPrice(newSliderValue)));
  };

  const handleMaxInputBlur = () => {
    let price = parseInt(inputMax, 10);
    if (isNaN(price)) {
      price = MAX_LIMIT;
    }
    if (price > MAX_LIMIT) {
      price = MAX_LIMIT;
    }
    const currentMinPrice = sliderValueToPrice(sliderMin);
    if (price < currentMinPrice + PRICE_GAP) {
      price = currentMinPrice + PRICE_GAP;
    }
    const newSliderValue = priceToSliderValue(price);
    setSliderMax(newSliderValue);
    setInputMax(String(sliderValueToPrice(newSliderValue)));
  };

  // Apply / Clear ボタンのハンドラ
  const handleApply = () => {
    updateQueryParams({
      minPrice: inputMin === "" ? null : inputMin,
      maxPrice: inputMax === "" ? null : inputMax,
    });
  };

  const handleClear = () => {
    setInputMin("");
    setInputMax("");
    setSliderMin(0);
    setSliderMax(100);
    updateQueryParams({
      minPrice: null,
      maxPrice: null,
    });
  };

  // スライダーのトラック表示（sliderMin, sliderMax は 0～100 の値）
  const sliderTrackStyle = {
    left: `${sliderMin}%`,
    right: `${100 - sliderMax}%`,
  };

  return (
    <>
      <div className="price-filter">
        <div className="price-filter__fields">
          <div className="price-filter__field">
            <input
              type="number"
              className="price-filter__field-input price-filter__field-input--min"
              value={inputMin}
              onChange={handleInputMinChange}
              onBlur={handleMinInputBlur}
              placeholder=""
            />
            <span className="price-filter__field-currency">円</span>
          </div>
          <div className="price-filter__field">
            <input
              type="number"
              className="price-filter__field-input price-filter__field-input--max"
              value={inputMax}
              onChange={handleInputMaxChange}
              onBlur={handleMaxInputBlur}
              placeholder=""
            />
            <span className="price-filter__field-currency">円</span>
          </div>
        </div>
        <div className="price-filter__slider-container">
          <div
            className="price-filter__slider-track"
            style={sliderTrackStyle}
          ></div>
        </div>
      </div>
      <div className="price-filter__ranges">
        {/* スライダーは 0～100 の値を扱うため step は 1 固定 */}
        <input
          type="range"
          className="price-filter__range-input price-filter__range-input--min"
          min={0}
          max={100}
          value={sliderMin}
          onChange={handleSliderMinChange}
          step={1}
        />
        <input
          type="range"
          className="price-filter__range-input price-filter__range-input--max"
          min={0}
          max={100}
          value={sliderMax}
          onChange={handleSliderMaxChange}
          step={1}
        />
      </div>
      <div className="filter-sidebar__buttons">
        <button
          className="filter-sidebar__button filter-sidebar__button--apply"
          onClick={handleApply}
        >
          OK
        </button>
        <button
          className="filter-sidebar__button filter-sidebar__button--clear"
          onClick={handleClear}
        >
          リセット
        </button>
      </div>
    </>
  );
};

export default PriceFilter;