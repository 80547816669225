import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom' // React Router の Link を使用

const Footer = () => {
    const currentYear = new Date().getFullYear()
    return (
        <footer className="bg-light py-3">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} md={6} className="text-center">
                        <p className="m-2 text-muted">
                            <Link to="/help/terms-of-service" className="mx-2 hover-underline">利用規約</Link> |
                            <Link to="/help/privacy-policy" className="mx-2 hover-underline">プライバシーポリシー</Link> |
                            <Link to="/help/legal-notice" className="mx-2 hover-underline">特定商取引法に基づく表記</Link> |
                            <Link to="/help/cancellation-return-policy" className="mx-2 hover-underline">キャンセル・返品ポリシー</Link>
                        </p>
                        <p className="m-2 text-muted">ふるぷら All rights reserved &copy; {currentYear}</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer