import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useQueryParams from '../../hooks/useQueryParams';
import { ListGroup, Offcanvas } from 'react-bootstrap';
import { GENDER_TO_ID } from '../../constants/productConstants';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import Tabs from '../../components/Tabs';
import SearchResults from '../../components/SearchResults';
import { useGetProductsQuery } from '../../slices/productsApiSlice';
import { useGetAreasQuery } from '../../slices/areasApiSlice';
import PriceFilter from '../../components/Sidebar/Filter/PriceFilter';
import SizeFilter from '../../components/Sidebar/Filter/SizeFilter';
import ColorFilter from '../../components/Sidebar/Filter/ColorFilter';
import GenderFilter from '../../components/Sidebar/Filter/GenderFilter';
import Breadcrumb from '../../components/SEO/Breadcrumb';

const SearchScreen = () => {
    const [query, updateQueryParams] = useQueryParams();
    const { parentCategory, category } = useParams();

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const keyword = query.keyword || '';
    const pageNumber = parseInt(query.page, 10) || 1;
    const selectedGender = query.gender || 'all';
    const selectedArea = query.area || 'all';
    const minPrice = query.minPrice ? parseInt(query.minPrice, 10) : '';
    const maxPrice = query.maxPrice ? parseInt(query.maxPrice, 10) : '';
    const sizes = query.size ? query.size.split('-').map(Number) : [];
    const colors = query.color ? query.color.split('-').map(Number) : [];

    const { data: areasData, isLoading: areasLoading, error: areasError } = useGetAreasQuery();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pageNumber, query]); // 依存配列にページ番号を含める

    // useGetProductsQueryの引数に反映
    const queryParams = {
        keyword,
        pageNumber,
        minPrice,
        maxPrice,
        sizes,
        colors,
        ...(parentCategory && { categorySlug: category || parentCategory }), 
        ...(selectedGender !== 'all' && { gender: GENDER_TO_ID[selectedGender] }),
        ...(selectedArea !== 'all' && { area: selectedArea }),
    };

    const { data: productsData, isLoading: productsLoading, error: productsError } = useGetProductsQuery(queryParams);

    const areaTabs = useMemo(() => {
        if (!areasData) return [];
        return [
            { label: 'すべて', value: 'all', ariaSelected: selectedArea === 'all' },
            ...areasData
                .filter(area => area.id !== 1) // 'その他' を除外（必要に応じてキーを調整）
                .map(area => ({
                    label: area.name,
                    value: String(area.id), // ID を文字列として設定
                    ariaSelected: selectedArea === String(area.id),
                }))
        ];
    }, [selectedArea, areasData]);

    if (productsLoading) return <Loader />;
    if (productsError) return <Message variant='danger'>商品データの取得に失敗しました</Message>;
    if (areasError) return <Message variant='danger'>エリアデータの取得に失敗しました</Message>;

    return (
        <>
        <div className='home-wrapper'>
            <div className='home-right'>
                <div className='ms-3'>
                    <Breadcrumb
                        items={[
                            { name: 'ふるぷら', url: '/' },
                            { name: '商品検索', url: '/search' },
                            parentCategory ? { name: parentCategory, url: `/category/${parentCategory}` } : null,
                            (parentCategory && category) ? { name: category, url: `/category/${parentCategory}/${category}` } : null,
                        ].filter(Boolean)}
                    />
                </div>
                <div className="search-heading">
                    <div className="search-heading__info">
                        <h1 className="search-heading__title">
                            {
                                keyword ||
                                (category &&
                                productsData?.data &&
                                productsData?.data[0]?.category &&
                                productsData.data[0].category.name) ||
                                category ||
                                parentCategory ||
                                (selectedArea !== 'all' && `「${areasData.find(a => a.id === Number(selectedArea))?.name}」の商品`) ||
                                `すべての商品`
                            }
                        </h1>
                        <div className="search-heading__count">
                            <span>{productsData.meta.count}</span>件
                        </div>
                        {/* <div className="search-heading__page">
                            <p>
                                {pageNumber}/{productsData.meta.pages} ページ
                            </p>
                        </div> */}
                    </div>
                        <button className="search-heading__filter" onClick={() => setShowOffcanvas(true)}>
                        こだわり条件
                        </button>
                </div>
                <Tabs
                    tabs={areaTabs}
                    onClick={(value) => updateQueryParams({ area: value })}
                />
                <SearchResults
                    productsData={productsData}
                    keyword={keyword}
                />
            </div>
        </div>

        <Offcanvas  show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement='end'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='fw-bolder'>こだわり条件</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='pb-4'>
                <ListGroup variant='flush'>
                    <ListGroup.Item>
                        <h3 className='fw-bolder fs-6'>性別</h3>
                        <GenderFilter query={query} updateQueryParams={updateQueryParams} />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h3 className='fw-bolder fs-6'>価格</h3>
                        <PriceFilter query={query} updateQueryParams={updateQueryParams} />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h3 className='fw-bolder fs-6'>サイズ</h3>
                        <SizeFilter query={query} updateQueryParams={updateQueryParams} />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h3 className='fw-bolder fs-6'>色</h3>
                        <ColorFilter query={query} updateQueryParams={updateQueryParams} />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <button className='btn btn-primary w-100' onClick={() => setShowOffcanvas(false)}>探す</button>
                    </ListGroup.Item>
                </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
        </>
    )
}

export default SearchScreen