import { Container, Row, Col } from 'react-bootstrap';
import { SUPPORT_EMAIL } from '../../constants/constants';
import Meta from '../../components/Meta';

const LegalNotice = () => {
  return (<>
    <Meta
      title='特定商取引法に基づく表記 | ふるぷら'
      description='ふるぷらの特定商取引法に基づく表記についてのページです。'
      keywords={['ふるぷら', '古着', '特定商取引法']}
    />
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={5}>
          <h1 className="fs-3 mb-4 text-center">特定商取引法に基づく表記</h1>

          <section className="mb-4">
            <h2 className='fs-6 mb-1 fw-bold'>販売事業者の名称</h2>
            <p>
                ふるぷら株式会社
            </p>
          </section>

          <section className="mb-4">
            <h2 className='fs-6 mb-1 fw-bold'>運営統括責任者</h2>
            <p>代表取締役社長 市東 豪</p>
          </section>

          <section className='mb-4'>
            <h2 className='fs-6 mb-1 fw-bold'>販売事業者の連絡先</h2>
            <p>
              メールアドレス: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> <br />
              電話番号: 080-8851-7072（電話対応は平日10:00~18:00のみ）
            </p>
          </section>

          <section>
            <h2 className='fs-6 mb-1 fw-bold'>販売事業者の所在地</h2>
            <p>
                東京都杉並区永福3-46-17-201 <br />
                ※ 返品交換や発送の受付窓口とは異なりますのでご注意ください。
            </p>
          </section>

          <section className="mb-4">
            <h2 className='fs-6 mb-1 fw-bold'>商品代金以外の必要料金</h2>
            <p>
              配送料（一律880円）<br />
              手数料（コンビニ決済: 110円）
            </p>
          </section>

          <section className="mb-4">
            <h2 className='fs-6 mb-1 fw-bold'>返品・交換の条件</h2>
            <p>
              誤配送や商品説明と著しい違いがある場合を除き、原則として返品・交換はできません。返品や交換が必要な場合は、<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> までご連絡ください。<br />
              詳細なポリシー内容については、<a className='text-decoration-underline' href='/help/cancellation-return-policy'>キャンセル・返品ポリシー</a>をご確認ください。
            </p>
          </section>

          <section className="mb-4">
            <h2 className='fs-6 mb-1 fw-bold'>商品の引き渡し時期</h2>
            <p>
                店舗受け取りの場合は受け取り店舗に来た際のお渡しいたします。<br />
                発送の場合、注文確定後、24時間以内に商品を保有する古着屋から発送いたします（商品が店舗にある場合の営業時間を除く）。
            </p>
          </section>

          <section className="mb-4">
            <h2 className='fs-6 mb-1 fw-bold'>受け付け可能な決済手段</h2>
            <p>
              クレジットカード、コンビニ決済、銀行振込。
              支払い方法は商品購入時に選択可能です。
            </p>
          </section>

          <section className="mb-4">
            <h2 className='fs-6 mb-1 fw-bold'>決済期間</h2>
            <p>
              支払い期限は注文確定メールが届いてから12時間以内とします。
              12時間以内にお支払いが確認できなかった場合、ご注文は自動的にキャンセル処理がなされますのでご注意ください。
            </p>
          </section>

          <section className="mb-4">
            <h2 className='fs-6 mb-1 fw-bold'>販売価格</h2>
            <p>税込み表記にて、各商品ページに記載。</p>
          </section>

          <section className="mb-4">
            <h2 className='fs-6 mb-1 fw-bold'>不良品の対応</h2>
            <p>
              商品に初期不良があった場合、商品お受け取り後速やかに、レンタル品の場合はレンタル期間終了前、購入品の場合は到着後8日以内に <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> までご連絡ください。確認の上、交換または返金対応いたします。
            </p>
          </section>
        </Col>
      </Row>
    </Container>
  </>);
};

export default LegalNotice;