import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";

const OrderCompleteScreen = () => {
    const navigate = useNavigate();

    return (<>
        <Meta
            title='注文完了 | ふるぷら'
            description='ふるぷらの注文完了ページです。'
            robots='noindex, nofollow'
        />
        <div className="order-complete">
            <div className="order-complete__content">
                <svg
                    className="order-complete__package"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                    >
                    <rect
                        className="order-complete__package-body"
                        x="8"
                        y="24"
                        width="48"
                        height="32"
                        fill="#fff"
                        stroke="#ccc"
                        strokeWidth="2"
                    />
                    <rect
                        className="order-complete__package-lid"
                        x="8"
                        y="16"
                        width="48"
                        height="12"
                        fill="#502200"
                    />
                </svg>

                <div className="order-complete__confetti">
                    <span className="order-complete__confetti-piece order-complete__confetti-piece--1"></span>
                    <span className="order-complete__confetti-piece order-complete__confetti-piece--2"></span>
                    <span className="order-complete__confetti-piece order-complete__confetti-piece--3"></span>
                </div>

                <h1 className="order-complete__title">ご注文ありがとうございます！</h1>
                <p className="order-complete__message">
                    お支払いが完了しました。詳細情報は注文履歴をご確認ください。
                </p>
                <Button
                    className="order-complete__button"
                    variant="primary"
                    onClick={() => navigate('/order-history')}
                >
                    注文履歴を見る
                </Button>
            </div>
        </div>
    </>);
};

export default OrderCompleteScreen;