import { Suspense, lazy } from "react";
import useDevice from "../../hooks/useDevice";
const HomeNavSidebarPC = lazy(() => import('../PC/HomeNavSidebar'));
const HomeNavSidebarSP = lazy(() => import('../SP/HomeNavSidebar'));
const NavSidebar = lazy(() => import('./NavSidebar'));

const HomeNavSidebar = ({ close }) => {
    const isMobile = useDevice();
    const SelectedHomeNavSidebar = isMobile ? HomeNavSidebarSP : NavSidebar;
  
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <SelectedHomeNavSidebar
          close={close}
        />
      </Suspense>
    );
  }

export default HomeNavSidebar;