import React, { useEffect } from 'react';
import { useJsonLd } from '../../context/JsonLdContext';
import { Breadcrumb as BootstrapBreadcrumb } from 'react-bootstrap';

const Breadcrumb = ({ items }) => {
    const { registerFragment } = useJsonLd();

    useEffect(() => {
        const breadcrumbFragment = {
            "@type": "BreadcrumbList",
            "itemListElement": items.map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": item.url,
            })),
        };
        registerFragment(breadcrumbFragment);
    }, [items, registerFragment]);

    return (
        <BootstrapBreadcrumb aria-label="breadcrumb">
            {items.map((item, index) => (
                <BootstrapBreadcrumb.Item
                    key={index}
                    href={index < items.length - 1 ? item.url : undefined}
                    active={index === items.length - 1}
                >
                    {item.name}
                </BootstrapBreadcrumb.Item>
            ))}
        </BootstrapBreadcrumb>
    );
};

export default Breadcrumb;